import React from 'react';
import {
  HashRouter,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import CommonFooter from '@/CommonFooter';
import CommonHeader from '@/CommonHeader';

import Home from 'pages/home';
import Stories from 'pages/stories';
import RunWay from 'pages/runWay';
import RunWayDetail from 'pages/runWayDetail';
import StoryDetail from 'pages/StoryDetail';
import LookBook from './components/LookBookList';
import LookBookDetail from './components/LookBookDetail';
import Store from './pages/store';
import TabCon from './pages/tabContainer';
import Contact from './pages/contact';
import Privacy from './pages/privacy';

const routes = [
  {
    path: "/",
    exact: true,
    component: Home
  },
  {
    path: "/footer",
    component: CommonFooter,
  },
  {
    path: "/stories",
    component: Stories,
  },
  {
    path: "/story/detail",
    component: StoryDetail,
  },

  {
    path: '/runway',
    exact: true,
    component: RunWay,
  },
  {
    path: '/runway/detail',
    component: RunWayDetail,
  },
  {
    path: '/lookbook',
    exact: true,
    component: LookBook,
  },
  {
    path: '/lookbook/detail',
    component: LookBookDetail,
  },
  {
    path: '/store',
    component: Store,
  },
  {
    path: '/brandStory',
    component: TabCon,
    exact: true
  },
  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/privacy',
    component: Privacy,
    hideCommon: true
  },
];

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        <>
          { route.hideCommon ?
            '' :
            <CommonHeader></CommonHeader>
          }
          {/* <MapLocation/> */}
          <route.component {...props} routes={route.routes} />
          { route.hideCommon ?
            '' :
            <CommonFooter></CommonFooter>
          }
        </>
      )}
    />
  );
}

export default function RouterApp() {
  const lang = localStorage.getItem('lang')
  const langClass = lang==='1' || lang==='2' ?'zh':'en';
  return (
    <HashRouter>
      <div className={`app-box ${langClass}`}>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
    </HashRouter>
  )
};