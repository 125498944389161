import React from "react";
import "./index.scss";
import { getImg, getVideo } from '../../../utils';
import ReactPlayer from "react-player";
import { Menu } from "antd";

export default class NavDetail extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {

  }

  render() {
    const { props } = this;
    // console.log(props)
    // 过滤没有图片的数据
    // const panelList = props.children.filter(item => item.thumb && item.thumb.length);
    const panelList = props.children;
    const panelEle = (data, index) => {
      let url, alt, videoUrl, type;
      if (data.thumb.length && data.thumb[0].hasOwnProperty('alt')) {
        // 图片
        let image = getImg(data.thumb);
        url = image.url;
        alt = image.alt;
        type = 'img';
      } else if (data.thumb.length && data.thumb[0].hasOwnProperty('title')) {
        // 视频
        let video = getVideo(data.thumb);
        videoUrl = video.videoUrl;
        type = 'video';
      } else {
        url = '';
        alt = '';
        type = 'img';
      }
      const play = (<span className="icon-play"></span>);
      return (
        <div className="item" key={index}>
          {type === 'img' && <a href={data.path}><img src={url} alt={alt} /></a>}
          {type === 'video' && <ReactPlayer className='react-player-nav' id="player2" url={videoUrl}
            controls playsinline={true}
            width='100%' height='100%' playIcon={play} />}
          <div className="desc">
            <div className="title">{data.name}</div>
            {data.children && data.children.map((text, i) => {
              return (
                  <a
                    href={text.path}
                    // href="#"
                    key={i}
                    onClick={()=>props.hide(text.path)}
                    >
                    <div className="cate">{text.name}</div>
                  </a>
              )
            })
            }
          </div>
        </div>

      )
    };

    return (
      <div className="content">
        <div className="top">
          <span className="nav-detail-title">{props.name}</span>
          <span className="line"></span>
        </div>

        {panelList.length === 1 || panelList.length === 2 || panelList.length === 5 ?
          <>
            <div className="main-list">
              {
                panelList.map((item, index) => {
                  if (index < 2) {
                    return panelEle(item, index)
                  }
                })
              }
            </div>

            {panelList.length > 2 && (<div className="sub-list">
              {
                panelList.map((item, index) => {
                  if (index > 1 && index < 5) {
                    return panelEle(item, index)
                  }
                })
              }
            </div>)}
          </> : (panelList.length === 3 ?
            <div className="sub-list">
              {
                panelList.map((item, index) => {
                  return panelEle(item, index)
                })
              }
            </div> : <>
              <div className="main-list">
                {
                  panelList.map((item, index) => {
                    if (index < 2) {
                      return panelEle(item, index)
                    }
                  })
                }
              </div>

              {panelList.length > 2 && (<div className="main-list">
                {
                  panelList.map((item, index) => {
                    if (index > 1 && index < 4) {
                      return panelEle(item, index)
                    }
                  })
                }
              </div>)}
            </>)
        }
      </div>
    );
  }
}
