import React from 'react';
import { getQuery, getImg, getVideo } from 'utils';
import { getLookBookList } from 'api';
import { Link } from "react-router-dom";
import intl from 'react-intl-universal';
import $ from 'jquery';
import '../../utils/jquery.lazyload.js';

import './index.scss';

export default class LookBookList extends React.Component {
  constructor() {
    super()
    this.state = {
      res: {},
      list: [],
      lookBookId: 0,
      lookBookDetailVisible: false,
      runwayId: ''
    }
  }

  componentDidMount() {
    const id = getQuery('id'); // runwayId
    this.setState({runwayId: id});
    sessionStorage.setItem('lookbook',1);
    getLookBookList(id).then(res => {
      // console.log(res)
      this.setState({res});
      this.setState({list: res.responses});
      for(let i=0; i< res.responses.length; i++) {
        $(`#img${i}`).fadeIn(Math.round(Math.random()*5000))
      }
    });
  }

  showLookBookDetail = lookBookId => {
    this.setState({
      lookBookId,
      lookBookDetailVisible: true
    })
  };

  goDetail = () =>{
    this.props.history.push('/lookbook/detail?id=4&&runwayId=4')
  };

  render() {
    const {list} = this.state;
    return (
      <div className="look-book-list-box">
        <div className="top">
          <div className="title">{this.state.res.title}</div>
          <div className="close-box" onClick={this.props.history.goBack}>
            <span className="txt">{intl.get('close')}</span>
            <span className="close icon"></span>
          </div>
        </div>
        <div className="sub-title">{this.state.res.subTitle}</div>

        <div className="panel">
          <div className="list">
            {
              list&&list.map((item,i) => {
                const {url, alt} = getImg(item.imageUrl)
                return (
                    <Link to={`/lookbook/detail?id=${item.id}&runwayId=${this.state.runwayId}&index=${i+1}`} key={item.id}>
                      <div className="item">
                        <img id={'img'+i} src={url} alt={alt} style={{backgroundColor: '#00000020'}}/>
                        <div className="mask">
                          <div className="title-con">
                            <div className="title">RUNWAY</div>
                            <div className="title">{item.title}</div>
                            <div className="num">{i+1}</div>
                          </div>
                        </div>
                      </div>
                    </Link>
                )
              })
            }


            {/*{
              this.state.lookBookDetailVisible ? <LookBookDetail id={this.state.lookBookId}/> : null
            }*/}

          </div>
        </div>
      </div>
    )
  }
}

