import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import "./styles/common.scss";
import "./styles/index.scss";
import 'swiper/swiper.scss';
import config from './config';
import intl from 'react-intl-universal';
import {emit} from './emit.js'
import zh_CN from 'antd/es/locale/zh_CN';
import en_US from 'antd/es/locale/en_US';
import zh_TW from 'antd/es/locale/zh_TW';
import ja_JP from 'antd/es/locale/ja_JP';
import { ConfigProvider } from 'antd';
import { getLang } from 'api';

import RouterApp from './router';
// locale data
const locales = {
  "en-US": require('./locales/en-US.json'),
  "zh-CN": require('./locales/zh-CN.json'),
  "zh-TW": require('./locales/zh-TW.json'),
  "ja-JP": require('./locales/ja-JP.json'),
};

window.app = {};
if(window.localStorage.getItem('lang')) {
  window.app.LANG = window.localStorage.getItem('lang')
} else {
  window.localStorage.setItem('lang', config.LANG);
  window.app.LANG = config.LANG
}

class App extends React.Component {
  state = {
    initDone: false,
    currentLang:''
  };

  componentWillMount() {
    // 获取语言
    getLang().then(res=>{
      switch (res.data) {
        case 'zh_CN':
          this.loadLocales('zh-CN');
          this.setState({currentLang: zh_CN});
          window.localStorage.setItem('lang', '1');
          window.app.LANG = 1;
          break;
        case 'zh_TW':
          this.loadLocales('zh-TW');
          this.setState({currentLang: zh_TW});
          window.localStorage.setItem('lang', '2');
          window.app.LANG = 2;
          break;
        case 'en_US':
          this.loadLocales( 'en-US');
          this.setState({currentLang: en_US});
          window.localStorage.setItem('lang', '3');
          window.app.LANG = 3;
          break;
        case 'ja_JP':
          this.loadLocales( 'ja-JP');
          this.setState({currentLang: ja_JP});
          window.localStorage.setItem('lang', '4');
          window.app.LANG = 4;
          break;
        default:
          this.loadLocales( 'en-US');
          this.setState({currentLang: en_US});
          window.localStorage.setItem('lang', '3');
          window.app.LANG = 3;
          break;
      }
      /*if(res.data === "zh_CN") {
        this.loadLocales('zh-CN');
        this.setState({currentLang: zh_CN});
        window.localStorage.setItem('lang', 1);
        window.app.LANG = 1
      } else {
        this.loadLocales( 'en-US');
        this.setState({currentLang: en_US});
        window.localStorage.setItem('lang', 3);
        window.app.LANG = 3
      }*/

    })
  }

  componentDidMount() {
    emit.on('change_language', lang => this.loadLocales(lang)); // 监听语言改变事件
  }

  loadLocales(lang) {
    console.log('lang:',lang);
    intl.init({
      currentLocale: lang,
      locales,
    }).then(() => {
      this.setState({initDone: true});
      const map={
        'zh-CN': zh_CN,
        'zh-TW': zh_TW,
        'en-US': en_US,
        'ja-JP': ja_JP
      };
      this.setState({currentLang: map[lang]});
    });
  }

  render() {
    return (
        <ConfigProvider locale={this.state.currentLang}>
        <RouterApp/>
        </ConfigProvider>
    );
  }


}

ReactDOM.render(<App />, document.getElementById('root'));