import React from 'react';
import {getRunWayList} from 'api';
import {getImg, getVideo, isVisible, checkFull, throttle, scrollToBottom} from 'utils';
import {Link} from "react-router-dom";
import intl from 'react-intl-universal';
import $ from 'jquery';

import './index.scss';
import ReactPlayer from "react-player";

export default class RuyWay extends React.Component {
  constructor() {
    super()
    this.state = {
      list: []
    }
  }

  // 进入页面强制滚动到顶部
  componentWillMount(){
    scrollToBottom(true)
  }

  componentDidMount() {

    getRunWayList().then(list => {
      // console.log(list, 1123)
      this.setState({list: list});

      window.onscroll = throttle(() => {
        let list = this.state.list;
        list.map((item,i)=>{
          let player1 = document.getElementById(i);
          let temp = isVisible(player1);
          item.autoPlay = temp;

          // 进入自动播放模式，设为静音
          if(temp) {
            item.mutedPlayer = true;
          }
        });
        this.setState({list})
      });

      window.onresize = throttle(() => {
        // 全屏下，放开静音。无法识别哪个视频为全屏模式，故，全部放开
        let list = this.state.list;
        if (checkFull()) {
          list.map((item,i)=>{
            item.mutedPlayer = false
          })
        } else {
          list.map((item,i)=>{
            item.mutedPlayer = true
          })
        }
        this.setState({list})
      });
    })
  }

  componentWillUnmount() {
    window.onscroll = '';
    window.onresize = '';
  }

  // 视频播放时不显示标题
  onPlay(index) {
    let list = this.state.list;
    list.map((item, i) => {
      if (index === i) {
        item.hideTitle = true
      }
    });
    this.setState({list})
  }

  // 视频播放结束显示标题
  onEnded(index) {
    let list = this.state.list;
    list.map((item,i)=>{
      if (index === i) {
        item.hideTitle = false
      }
    });
    this.setState({list})
  }

  // 视频暂停显示标题
  onPause(index) {
    let list = this.state.list;
    list.map((item,i)=>{
      if (index === i) {
        item.hideTitle = false
      }
    });
    this.setState({list})
  }

  render() {
    // let {list} = this.state;
    // console.log(list)
    const playIcon = (<span className="icon-play"></span>);
    return (
        <div className="runway-box">
          <div className="title-box">
            <span className="title">{intl.get('RUNWAY')}</span>
          </div>

          {
            this.state.list.length ?
                <div className="runway-list">
                  {
                    this.state.list.map((item,index) => {
                      const {url, alt} = getImg(item.imageUrl);
                      // let voidUrl = [ {
                      //   "title" : "19AW_celebrities_video.mp4",
                      //   "file" : "/uploads/file/20200629/1593408544561027562.mp4"
                      // } ];
                      const {videoUrl, title} = item.voidUrl ? getVideo(item.voidUrl) : {};
                      // const {videoUrl, title} = voidUrl ? getVideo(voidUrl) : {};
                      // item.title = item.title.replace(/\n/g, "<br/>");
                      return (
                          <div className="item" key={item.id}>
                            <Link to={`/runway/detail?id=${item.id}`}>

                              {item.voidUrl ? (
                                  // <ReactPlayer className='react-player runway-player' id={index}
                                  //              light={item.autoPlay ? false : url} playIcon={playIcon}
                                  //              url={videoUrl} width="100%"
                                  //              onPlay={()=>{this.onPlay(index)}} onEnded={()=>{this.onEnded(index)}}
                                  //              onPause={()=>{this.onPause(index)}}
                                  //              playing={item.autoPlay} muted={item.mutedPlayer}

                                  // />
                                  <ReactPlayer className='react-player runway-player' id={index}
                                         light={false} playIcon={playIcon}
                                         url={videoUrl} width="100%" playsinline={true}
                                         playing={true} muted={true}
                                         loop={true}

                            />
                              ) : <img src={url} alt={alt}/>}
                              <div className="modal"></div>
                              {(!item.hideTitle || !item.autoPlay) &&
                              <div className="title">{item.title}</div>}
                            </Link>
                          </div>
                      )
                    })
                  }
                </div>
                : null
          }
        </div>
    )
  }
}