import React from 'react';
import QRCode from 'qrcode.react';
import { getQuery, getImg, getVideo, throttle, scrollToBottom } from 'utils';
import { getRunWayDetail, getIcon } from 'api';
import config from 'src/config';
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player';
import intl from 'react-intl-universal';
import $ from 'jquery';

import './index.scss';

export default class RunWayDetail extends React.Component {
  constructor() {
    super()
    this.state = {
      detail: {},
      runwayId: '',
      lookBookVisible: false,
      lookBookListVisible: false,
      firstVideoImg: '',
      firstVideoUrl: '',
      secondVideoImg: '',
      secondVideoUrl: '',
      readMoreAdv: false,
      advListEle: '',
      before: '',
      after: '',
      showDesc: false, // 是否展示全部文字
      autoPlay1: false, // 视频1是否自动播放，当处于页面中时才会自动播放，离开页面即停止
      autoPlay2: false, // 视频2是否自动播放，当处于页面中时才会自动播放，离开页面即停止
      mutedPlayer1: true, // 视频默认静音
      mutedPlayer2: true,
      iconList: []
    }
  }

  // 进入页面强制滚动到顶部
  componentWillMount(){
    scrollToBottom(true)
  }

  componentDidMount() {
    const runwayId = getQuery('id');
    this.setState({runwayId})
    this.getDetail(runwayId);
    getIcon().then(res=> {
      // console.log('iconList:',res)
      this.setState({iconList: res.data})
    });

    window.onscroll = throttle(() => {
      // let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log("滚动距离" + scrollTop);

      let player1 = document.getElementById('player1');
      let player2 = document.getElementById('player2');
      let temp1 = this.isVisible(player1);
      let temp2 = this.isVisible(player2);

      this.setState({autoPlay1: temp1 });
      this.setState({autoPlay2: temp2 });
      // 进入自动播放模式，设为静音
      if(temp1) {
        this.setState({mutedPlayer1: true})
      }
      if(temp2) {
        this.setState({mutedPlayer2: true})
      }
    });

    window.onresize = throttle(() => {
      // 全屏下，放开静音。无法识别哪个视频为全屏模式，故，全部放开
      if (this.checkFull()) {
        this.setState({mutedPlayer1: false, mutedPlayer2: false})
      } else {
        this.setState({mutedPlayer1: true, mutedPlayer2: true})
      }
    });

  }

  componentWillReceiveProps(nextProps){
    // 获取浏览器地址携带的tab参数
    const id = getQuery('id');
    if (id) {
      this.getDetail(id);
      document.getElementById('root').scrollIntoView(true);
    }
  }

  componentWillUnmount() {
    window.onscroll = ''
  }

  // 元素是否在视频中央
  isVisible = (dom) => {
    if (dom) {
      var rect = dom.getBoundingClientRect();
      return (
          rect.top >= 0 && rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  };

  // 判断是否有全屏事件,全屏时放开静音
  checkFull() {
    var isFull = document.fullscreenEnabled || window.fullScreen || document.webkitIsFullScreen || document.msFullscreenEnabled;
    // to fix : false || undefined == undefined
    if (isFull === undefined) {
      isFull = false;
    }
    return isFull;
  }

  getDetail(runwayId) {
    getRunWayDetail(runwayId).then(detail => {
          this.setState({
            detail,
            runwayId,
            before: detail.before,
            after: detail.after,
            firstVideoImg: getImg(detail.oneVideoImageUrl).url,
            firstVideoUrl: `${config.VIDEO_URL}${detail.oneVideoUrl}`,
            secondVideoImg: getImg(detail.twoVideoImageUrl).url,
            secondVideoUrl: `${config.VIDEO_URL}${detail.twoVideoUrl}`,
          });
          this.setState({advListEle:this.getAdvList()});
          this.setState({readMoreAdv: false});
          this.goLookbookPanel()
        }
    )
  }

  // 从广告列表返回此页面，定位到lookbookPanel模块
  goLookbookPanel() {
    if (sessionStorage.getItem('lookbook')) {
      document.getElementById('lookbookPanel').scrollIntoView(true);
      sessionStorage.removeItem('lookbook')
    }
  }

  // 渲染广告列表
  getAdvList(flag) {
    let url1,url2,url3,title1,title2,title3,subTitle1,subTitle2,subTitle3;
    let href1,href2,href3;
    let result = [];
    let ele = '';
    if(this.state.detail&&this.state.detail.runwayAdvertisingResponses) {
      // 将广告列表每3个拆分为一组
      for (let i = 0; i < this.state.detail.runwayAdvertisingResponses.length; i += 3) {
        result.push(this.state.detail.runwayAdvertisingResponses.slice(i, i + 3));
      }
      // console.log('result:',result)
      result.map((adv, index) => {
        if(!flag && index>0) {
          return
        }
        adv.map((item, i) => {
          // console.log(123123,item)
          const {url, alt} = getImg(item.imageUrl);
          if (i === 0) {
            url1 = url;
            href1 = item.link;
            title1 = item.title ? item.title : '';
            subTitle1 = item.subTitle ? item.subTitle : '';
          } else if (i === 1) {
            url2 = url;
            href2 = item.link;
            title2 = item.title ? item.title : '';
            subTitle2 = item.subTitle ? item.subTitle : '';
          } else {
            url3 = url;
            href3 = item.link;
            title3 = item.title ? item.title : '';
            subTitle3 = item.subTitle ? item.subTitle : '';
          }
        });
        if ((index + 1) % 2 !== 0) {
          ele += `<div class="ad-box">
            <div class="part">
              <div class="img-box1">
              <a href="${href1}" style="${href1==""?"pointer-events:none":""}">
              <img src="${url1}"/>
              </a>
              <span class="title">${title1}</span>
              <span class="sub-title">${subTitle1}</span>
              </div>
              <div class="multi">
                <div class="img-box2">
                <a href="${href2}" style="${href2==""?"pointer-events:none":""}"><img src="${url2}"/></a>
                <span class="title">${title2}</span>
              <span class="sub-title">${subTitle2}</span>
                </div>
                <div class="img-box3">
                <a href="${href3}" style="${href3==""?"pointer-events:none":""}"><img src="${url3}"/></a>
                <span class="title">${title3}</span>
              <span class="sub-title">${subTitle3}</span>
                </div>
              </div>
            </div>
          </div>`
        } else {
          ele += `<div class="ad-box">
            <div class="part">
              <div class="multi">
                <div class="img-box4">
                <a href="${href1}" style="${href1==""?"pointer-events:none":""}"><img src="${url1}"/></a>
                <span class="title">${title1}</span>
              <span class="sub-title">${subTitle1}</span>
              </div>
                <div class="img-box5">
                <a href="${href1}" style="${href1==""?"pointer-events:none":""}"><img src="${url2}"/></a>
                <span class="title">${title2}</span>
              <span class="sub-title">${subTitle2}</span>
                </div>
              </div>
              <div class="img-box6">
              <a href="${href1}" style="${href1==""?"pointer-events:none":""}"><img src="${url3}"/></a>
              <span class="title">${title3}</span>
              <span class="sub-title">${subTitle3}</span>
              </div>
            </div>
          </div>`
        }
      });
      return ele
    }
  };

  // 展开收起广告图集
  toggleAdvList(status) {
    // console.log('111',status)
    // console.log('222',this.state.readMoreAdv)
    this.setState({readMoreAdv: status})
    this.setState({advListEle:this.getAdvList(status)})
  };

  showLookBook = () => {
    this.setState({ lookBookListVisible: true })
    this.props.history.push(`/lookbook?id=${this.state.runwayId}`)
  };

  hideLookBook = () => {
    this.setState({ lookBookListVisible: false })
  };

  // 上一页，下一页
  changeRunway = (type) => {
    if(this.state[type]) {
      let id = this.state[type].id;
      this.getDetail(id);
      document.getElementById('root').scrollIntoView(true);
    }
  };

  // 点击减号回到简介顶部
  goIntoDescPanel = ()=> {
    this.setState({showDesc: false})
    document.getElementById('desc').scrollIntoView(true);
  };

  render() {
    let beforeImage,beforeAlt,afterImage,afterAlt;
    const { detail, iconList } = this.state;
    const { lookBookResponses, runwayAdvertisingResponses } = detail;
    const play = (<span className="icon-play"></span>);

    const beforePic = this.state.before && this.state.before.imageUrl ? getImg(this.state.before.imageUrl):'';
    if (beforePic) {
      beforeImage = beforePic.url;
      beforeAlt = beforePic.alt;
    }
    const afterPic = this.state.after && this.state.after.imageUrl ? getImg(this.state.after.imageUrl):'';
    if (afterPic) {
      afterImage = afterPic.url;
      afterAlt = afterPic.alt;
    }

    return (
      <div className="run-way-detail-box">
        {
          detail.id ?
            <div className="safe">
              <div className="nav-box">
                <Link to="/runway">{intl.get('RUNWAY')}</Link>
                <span>{detail.breadTitle}</span>
              </div>

              <div className="main-title">{detail.title}</div>
              <div className="main-title-line"></div>
              {detail.oneVideoUrl ?
                  <div className='player-wrapper vid1'>
                    <ReactPlayer className='react-player' id="player1" url={getVideo(detail.oneVideoUrl).videoUrl}
                                 playing={this.state.autoPlay1} controls muted={this.state.mutedPlayer1}
                                 playsinline={true}
                                 light={this.state.autoPlay1 ? false : getImg(detail.oneVideoImageUrl).url} width='100%'
                                 height='100%' playIcon={play}/></div>
                  :
                  <img src={getImg(detail.oneVideoImageUrl).url} style={{width: '100%'}}/>
              }
              <div className="introduce">
                <div className="title">{detail.introduceTitle}</div>
                <div className="sub-title">{detail.introduceSubtitle}</div>
                <div className={`desc ${this.state.showDesc? 'show-all': 'show-part'}`} id="desc" dangerouslySetInnerHTML={{ __html: detail.introduce }}></div>
                {this.state.showDesc ? (<span className="aaSquarePlus" onClick={this.goIntoDescPanel} style={{lineHeight:'32px'}}>-</span>) :
                    (<span className="aaSquarePlus" onClick={()=>this.setState({showDesc: true})}>+</span>)}
              </div>

              {lookBookResponses.length ? <div className="look-book-box" id="lookbookPanel" style={{borderBottom:(detail.twoVideoUrl||detail.twoVideoImageUrl)?'1px solid #90816d':'none'}}>
                <div className="img-list">
                  {
                    lookBookResponses.map((res, index) => {
                      if (index < 3) {
                        const { url, alt } = getImg(res.imageUrl);
                        return (
                            <Link to={`/lookbook/detail?id=${res.id}&runwayId=${this.state.runwayId}&index=${index}`}
                                  key={res.id}>
                              <div className="item" key={res.id}>
                                <img src={url} alt={alt}/>
                              </div>
                            </Link>
                        )
                      }
                    })
                  }
                </div>
                <span className="aaSquarePlus" onClick={this.showLookBook}>+</span>
              </div> : ''}

              {detail.twoVideoUrl ?
                  <div className="player-wrapper second-video">
                    <ReactPlayer className='react-player' id="player2" url={getVideo(detail.twoVideoUrl).videoUrl}
                                 playing={this.state.autoPlay2} controls muted={this.state.mutedPlayer2}
                                 playsinline={true}
                                 light={this.state.autoPlay2 ? false : getImg(detail.twoVideoImageUrl).url}
                                 width='100%' height='100%' playIcon={play}/>
                  </div> :(
                      detail.twoVideoImageUrl ?
                  <div className="second-video">
                    <img src={getImg(detail.twoVideoImageUrl).url} style={{width: '100%'}}/>
                  </div>: '')
              }

                {/*广告列表*/}
              <div className="ad-box-con" dangerouslySetInnerHTML={{__html: this.state.advListEle}}></div>
              {(detail.twoVideoUrl||detail.twoVideoImageUrl)&&runwayAdvertisingResponses&&runwayAdvertisingResponses.length>3&&<>
              {this.state.readMoreAdv ? (<span className="minus-icon" onClick={() => this.toggleAdvList(false)}></span>) :
                  (<span className="aaSquarePlus" onClick={() => this.toggleAdvList(true)}>+</span>)}</>}

              <div className="bottom-share">
                {iconList.map(icon=>{
                  switch (icon.icon) {
                    case 'facebook':
                      return icon.location.includes("2")?(<a className="icon-share" target="_blank" href={'https://www.facebook.com/sharer.php?title=' + detail.introduceTitle + '&u=' + encodeURIComponent(window.location.href) }>
                        <svg id="facebook" viewBox="0 0 32 32">
                          <path
                              d="M24,6.6h-4.6c-0.5,0-1.1,0.7-1.1,1.7v3.3H24v4.7h-5.7v14.1h-5.4V16.3H8v-4.7h4.9V8.8c0-4,2.8-7.2,6.5-7.2H24V6.6L24,6.6z"></path>
                        </svg>
                      </a>):'';
                      break;
                    case 'twitter':
                      return icon.location.includes("2")?(
                          <a className="icon-share" target="_blank" href={'https://twitter.com/share?text=' + detail.introduceTitle + '&url=' + encodeURIComponent(window.location.href) }>
                            <svg id="twitter" viewBox="0 0 32 32">
                              <path d="M30.7,6.9c-1.1,0.5-2.2,0.8-3.5,1c1.2-0.7,2.2-1.9,2.7-3.3c-1.2,0.7-2.5,1.2-3.8,1.5C25,4.8,23.4,4,21.7,4c-3.3,0-6,2.7-6,6
                c0,0.5,0.1,0.9,0.2,1.4c-5-0.3-9.5-2.7-12.4-6.3C2.8,6,2.5,7.1,2.5,8.2c0,2.1,1.1,3.9,2.7,5c-1,0-1.9-0.3-2.7-0.8c0,0,0,0.1,0,0.1
                c0,2.9,2.1,5.4,4.8,5.9c-0.5,0.1-1,0.2-1.6,0.2c-0.4,0-0.8,0-1.1-0.1c0.8,2.4,3,4.1,5.6,4.2c-2.1,1.6-4.7,2.6-7.5,2.6
                c-0.5,0-1,0-1.4-0.1C4,27,7.1,28,10.5,28c11.1,0,17.2-9.2,17.2-17.2c0-0.3,0-0.5,0-0.8C28.9,9.1,29.9,8.1,30.7,6.9L30.7,6.9z"></path>
                            </svg>
                          </a>
                      ):'';
                      break;
                    case 'weibo':
                      return icon.location.includes("2")?(<a className="icon-share" target="_blank" href={'http://service.weibo.com/share/share.php?title=' + detail.introduceTitle + '&url=' + encodeURIComponent(window.location.href) }>
                        <svg id="weibo" viewBox="0 0 32 32">
                          <path
                              d="M12.054 22.929q0.375-0.607 0.196-1.232t-0.804-0.893q-0.607-0.25-1.304-0.018t-1.071 0.821q-0.393 0.607-0.232 1.223t0.768 0.902 1.33 0.045 1.116-0.848zM13.732 20.768q0.143-0.232 0.063-0.473t-0.313-0.33q-0.25-0.089-0.509 0.009t-0.384 0.33q-0.304 0.554 0.232 0.804 0.25 0.089 0.518-0.009t0.393-0.33zM16.839 22.679q-0.804 1.821-2.821 2.679t-4 0.214q-1.911-0.607-2.634-2.259t0.116-3.348q0.839-1.661 2.705-2.482t3.759-0.339q1.982 0.518 2.83 2.134t0.045 3.402zM22.411 19.821q-0.161-1.714-1.589-3.036t-3.723-1.946-4.902-0.375q-3.982 0.411-6.598 2.527t-2.366 4.723q0.161 1.714 1.589 3.036t3.723 1.946 4.902 0.375q3.982-0.411 6.598-2.527t2.366-4.723zM27.911 19.893q0 1.214-0.661 2.491t-1.946 2.446-3.009 2.098-4.036 1.482-4.83 0.554-4.911-0.598-4.295-1.661-3.063-2.696-1.161-3.563q0-2.054 1.241-4.375t3.527-4.607q3.018-3.018 6.098-4.214t4.402 0.125q1.161 1.143 0.357 3.732-0.071 0.25-0.018 0.357t0.179 0.125 0.259-0.009 0.241-0.063l0.107-0.036q2.482-1.054 4.393-1.054t2.732 1.089q0.804 1.125 0 3.179-0.036 0.232-0.080 0.357t0.080 0.223 0.214 0.134 0.304 0.107q1.018 0.321 1.839 0.839t1.429 1.455 0.607 2.080zM26.589 8.75q0.75 0.839 0.973 1.938t-0.116 2.098q-0.143 0.411-0.527 0.607t-0.795 0.071q-0.411-0.143-0.607-0.527t-0.071-0.795q0.357-1.125-0.429-1.982t-1.911-0.625q-0.429 0.089-0.804-0.143t-0.446-0.661q-0.089-0.429 0.143-0.795t0.661-0.455q1.071-0.232 2.125 0.098t1.804 1.17zM29.821 5.839q1.554 1.714 2.009 3.973t-0.241 4.313q-0.161 0.482-0.607 0.714t-0.929 0.071-0.714-0.607-0.089-0.929q0.5-1.464 0.179-3.071t-1.429-2.821q-1.107-1.232-2.643-1.705t-3.089-0.152q-0.5 0.107-0.929-0.17t-0.536-0.777 0.17-0.92 0.777-0.527q2.196-0.464 4.357 0.205t3.714 2.402z"></path>
                        </svg>
                      </a>):'';
                    // case 'youku':
                    //   return icon.location.includes("2")?(<a className="icon-share" target="_blank" href="http://i.youku.com/shiatzychen">
                    //     <svg id="youku" viewBox="0 0 32 32">
                    //       <path d="M6 4l20 12-20 12z"></path>
                    //     </svg>
                    //   </a>):'';
                    case 'wechat':
                      const wxUrl = 'https://new-m.shiatzychen.com/' + window.location.hash;
                      return icon.location.includes("2")?(<a className="icon-share icon-wx">
                        <svg id="wechat" viewBox="0 0 37 32">
                          <path
                              d="M10.357 8.232q0-0.732-0.446-1.179t-1.179-0.446q-0.768 0-1.357 0.455t-0.589 1.17q0 0.696 0.589 1.152t1.357 0.455q0.732 0 1.179-0.438t0.446-1.17zM23.625 17.286q0-0.5-0.455-0.893t-1.17-0.393q-0.482 0-0.884 0.402t-0.402 0.884q0 0.5 0.402 0.902t0.884 0.402q0.714 0 1.17-0.393t0.455-0.911zM19.411 8.232q0-0.732-0.438-1.179t-1.17-0.446q-0.768 0-1.357 0.455t-0.589 1.17q0 0.696 0.589 1.152t1.357 0.455q0.732 0 1.17-0.438t0.438-1.17zM30.75 17.286q0-0.5-0.464-0.893t-1.161-0.393q-0.482 0-0.884 0.402t-0.402 0.884q0 0.5 0.402 0.902t0.884 0.402q0.696 0 1.161-0.393t0.464-0.911zM26 10.196q-0.554-0.071-1.25-0.071-3.018 0-5.554 1.375t-3.991 3.723-1.455 5.134q0 1.393 0.411 2.714-0.625 0.054-1.214 0.054-0.464 0-0.893-0.027t-0.982-0.116-0.795-0.125-0.973-0.188-0.893-0.188l-4.518 2.268 1.286-3.893q-5.179-3.625-5.179-8.75 0-3.018 1.741-5.554t4.714-3.991 6.491-1.455q3.143 0 5.938 1.179t4.679 3.259 2.438 4.652zM36.571 20.214q0 2.089-1.223 3.991t-3.313 3.455l0.982 3.232-3.554-1.946q-2.679 0.661-3.893 0.661-3.018 0-5.554-1.259t-3.991-3.42-1.455-4.714 1.455-4.714 3.991-3.42 5.554-1.259q2.875 0 5.411 1.259t4.063 3.429 1.527 4.705z"></path>
                        </svg>
                        <div className="qrcode">
                          <QRCode value={wxUrl} />
                          <p>请使用微信扫描二维码来分享</p>
                        </div>
                      </a>):'';
                      default:
                        break
                  }
                })}
              </div>
              <div className="bottom-img-box">
                {this.state.before?<div className="item">
                    <div className="content" onClick={() => this.changeRunway('before')}>
                      <Link className="black" to={`/runway/detail?id=${this.state.before && this.state.before.id}`}>
                        <img src={beforeImage} alt={beforeAlt}/>
                      </Link>
                      <div className="title">
                        <Link to={`/runway/detail?id=${this.state.before && this.state.before.id}`}>
                          {this.state.before && this.state.before.title}
                        </Link>
                      </div>
                      <div className="link">
                        <Link to={`/runway/detail?id=${this.state.before && this.state.before.id}`}>
                          VIEW THIS <span>&gt;</span>
                        </Link>
                      </div>
                    </div>
                </div>:''}

                {this.state.after?<div className="item">
                    <div className="content" onClick={() => this.changeRunway('after')}>
                      <Link className="black" to={`/runway/detail?id=${this.state.after && this.state.after.id}`}>
                        <img src={afterImage} alt={afterAlt}/>
                      </Link>
                      <div className="title">
                        <Link to={`/runway/detail?id=${this.state.after && this.state.after.id}`}>
                          {this.state.after && this.state.after.title}
                        </Link>
                      </div>
                      <div className="link">
                        <Link to={`/runway/detail?id=${this.state.after && this.state.after.id}`}>
                          VIEW THIS <span>&gt;</span>
                        </Link>
                      </div>
                    </div>
                </div>:''}
              </div>
            </div>
            : null
        }
      </div>
    )
  }
}
