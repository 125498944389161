import React from 'react';
import './index.scss';
import markIcon from 'assets/images/location.png';
import $ from 'jquery';

export default class MapLocation extends React.Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.marker = [];
    this.infowindow = [];
    this.showCity = true;
    this.mapType = 'A'
  }

  // 高德地图
  loadAMap = () => {
    this.mapType = 'A';
    const url = `https://webapi.amap.com/maps?v=1.4.15&key=77a3e473be34d81c13a53e3240b95070&callback=onAMapLoad&&plugin=AMap.Scale,AMap.OverView,AMap.ToolBar`
    const jsapi = document.createElement('script')
    jsapi.charset = 'utf-8'
    jsapi.src = url
    document.head.appendChild(jsapi);

    window.onAMapLoad = () => {
      console.log('地图已加载')
      var toolBar = new window.AMap.ToolBar({
        visible: true
      })
      this.map = new window.AMap.Map('map-box', {
        mapStyle: "amap://styles/whitesmoke",
        center: [116.406315, 39.908775],
        zoom: 14
      })
      this.map.addControl(toolBar);
      this.updateMap();
    }
  };

  // 百度地图
  loadBMap = () => {
    this.mapType = 'B';
    const url = `https://api.map.baidu.com/api?v=3.0&ak=GgHjw11mitCe2jvbdaYB31EzxOIxNCj8&callback=onBMapLoad`
    const jsapi = document.createElement('script')
    jsapi.charset = 'utf-8'
    jsapi.src = url
    document.head.appendChild(jsapi);

    window.onBMapLoad = () => {
      console.log('百度地图index已加载')
      // var toolBar = new window.AMap.ToolBar({
      //   visible: true
      // })
      this.map = new window.BMap.Map('map-box');
      this.map.addControl(new window.BMap.NavigationControl());
      // this.map.addControl(new window.BMap.ScaleControl());
      // this.map.addControl(new window.BMap.OverviewMapControl());
      // this.map.addControl(new window.BMap.MapTypeControl());
      this.map.enableScrollWheelZoom(true);
      // var ggPoint = new window.BMap.Point(116.406315, 39.908775);
      // this.map.centerAndZoom(ggPoint, 11);
      this.updateMap();
    }
  };
  updateMap = () => {
    // console.log(111, this.props);
    // console.log(222, this.map);

    if (this.mapType === 'A') {
      const icon = new window.AMap.Icon({
        size: new window.AMap.Size(24, 56),
        image: markIcon
      });
      // set city
      if (this.showCity) {
        if (this.props.storeList.length > 0) {
          // console.log(this.props)
          // this.props.storeList[0].localPoint.split(",")
          this.map.setCenter(this.props.storeList[0].localPoint.split(","));
          this.map.setZoom(13);
          // this.map.getCity(function(info){
          //   console.log(info)
          // })
        }
      }
      if (this.marker.length) {
        this.map.remove(this.marker);
        this.marker = []
      }
      // set store
      for (let item of this.props.storeList) {
        this.marker.push(new window.AMap.Marker({
          icon,
          position: item.localPoint.split(","),
          anchor: 'bottom-center',
          // offset: new window.AMap.Pixel(0, 0),
          label: {
            content: item.name,
            direction: 'center',
            offset: new window.AMap.Pixel(0, -45)
          }
        }));
        // marker.setMap(this.map);
      }
      if (this.marker.length) {
        this.marker.map(marker => {
          marker.setMap(this.map)
        })
      }
    } else if (this.mapType === 'B') {
      var myIcon = new window.BMap.Icon(markIcon, new window.BMap.Size(24, 56));
      if (this.showCity) {
        if (this.props.storeList.length > 0) {
          // var pp = this.props.storeList[0].localPoint.split(",")
          // var ggpoint = new window.BMap.Point(pp[0], pp[1]);
          this.map.centerAndZoom(this.props.location);
          // this.map.setCenter(ggpoint)
          // this.map.setZoom(11)
        }
      }
      if (this.marker.length) {
        this.map.clearOverlays();
        this.marker = []
      }
      // set store
      for (let item of this.props.storeList) {
        // console.log(item)
        var pp2 = item.localPoint.split(",");
        var ggpoint2 = new window.BMap.Point(pp2[0], pp2[1]);
        var label = new window.BMap.Label(item.name,{offset:new window.BMap.Size(28,2)});
        var marker = new window.BMap.Marker(ggpoint2, {icon: myIcon});
        label.setStyle({
          color: '#00000085',
          backgroundColor: "#fff",
          border: "1px solid #d4d4d4"
        })
        marker.setLabel(label); //添加百度label
        this.map.addOverlay(marker);
      }
    } else {
      if (this.props.storeList.length <= 0) return
      let firstPoint = this.props.storeList[0].localPoint.split(",")
      this.map.setCenter(window.google.maps.LatLng(firstPoint[1], firstPoint[0]))

      // 清空上次搜索的所有标记
      if (this.marker.length) {
        this.marker.map(marker => {
          marker.setMap(null)
        });
        this.marker = []
      }

      this.props.storeList.map((item, index) => {
        let newPoint = item.localPoint.split(",");
        this.marker.push(new window.google.maps.Marker({
          position: new window.google.maps.LatLng(newPoint[1], newPoint[0]),
          icon: {
            url: markIcon,
            labelOrigin: new window.google.maps.Point(5, -10)
          },
          map: this.map,
          label: {
            text: item.name,
            color: '#00000085',
            fontWeight: 'bolder'
          }
        }));
        //创建一个InfoWindow
        // infowindow.open(map, marker); //把这个infoWindow绑定在选定的marker上面
        //使用谷歌地图定义的事件，给这个marker添加点击事件
        // let infowindow = new window.google.maps.InfoWindow({
        //   content: "<div class='marker-container'>" +item.name +"</div>"
        // });
        // infowindow.open(this.map,marker);

      });
      // 重新设置此次搜索的所有标记
      if (this.marker.length) {
        this.marker.map(marker => {
          marker.setMap(marker)
        })
      }
    }
  };

  // 谷歌地图
  loadGoogleMap = () => {
    window.google = {};
    // const googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAXM8Qzay_fYN4pgAFXkPZDNCptHOyYrBA&libraries=places&callback=initGoogleMap`;
    let lang = localStorage.getItem('lang');
    let googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap`;
    switch (lang) {
      case '2':
        googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap&language=zh&region=TW`;
        break;
      case '3':
        googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap&language=en&region=US`;
        break;
      case '4':
        googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap&language=ja&region=JP`;
        break;
      default:
        googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVUka4j0M-24MeD_zGp7RcH3ehGJyZ-ME&callback=initGoogleMap&language=en&region=US`;
        break;
    }
    const that = this;
    $.ajax({
      url: googleMapUrl,
      type: "get",
      dataType: "jsonp",
      success: function () {
        console.log(1111111)
        const jsapi = document.createElement('script');
        jsapi.charset = 'utf-8';
        jsapi.src = googleMapUrl;
        document.head.appendChild(jsapi);
        that.mapType = 'G';
      },
      error: function (err) {
        console.log(2222222,err)
        that.loadBMap();
      }
    });

    window.initGoogleMap = () => {
      var uluru = {
        lat: -25.344,
        lng: 131.036
      };

      this.map = new window.google.maps.Map(document.getElementById("map-box"), {
        center: uluru,
        zoom: 14,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
      });
      /*this.marker = new window.google.maps.Marker({
        position: uluru,
        icon: markIcon,
        map: this.map
      });*/
    }

  };

  // 改变地图中心点及缩放级别
  changePoint(point, flag) {
    let newPoint = point ? point.split(',') : '';
    if (this.mapType === 'A') {
      newPoint = newPoint.map(item => item = Number(item));

      if (flag) {
        this.showCity = false
      } else {
        this.showCity = true
      }

      this.map.setZoomAndCenter(24, newPoint);
    } else if (this.mapType === 'B') {
      newPoint = newPoint.map(item => item = Number(item));

      if (flag) {
        this.showCity = false
      } else {
        this.showCity = true
      }
      // console.log(newPoint)
      var ggpoint = new window.BMap.Point(newPoint[0], newPoint[1]);
      this.map.centerAndZoom(ggpoint, 18);
    } else {
      if (window.google && newPoint.length && this.map) {
        this.map.setZoom(15);
        /*if(this.marker.length) {
          this.marker.map(marker=>{
            marker.setMap(null)
          })
        }*/
        // this.map.panTo(new window.google.maps.LatLng(newPoint[1], newPoint[0]))
        this.map.setCenter(new window.google.maps.LatLng(newPoint[1], newPoint[0]))

      } else if (window.google && !newPoint.length) {
        this.map.setCenter(new window.google.maps.LatLng('', ''))
      }
    }
  }

  componentDidMount() {
    // 加载高德地图
    if (localStorage.getItem('lang') === '1') {
      // 中文模式下用高德
      this.loadBMap();
      // this.loadAMap();
    } else {
      // 加载谷歌地图
      this.loadGoogleMap()
    }

    this.props.onRef(this)
  }


  render() {
    if (this.map) this.updateMap();
    return (
        <div id = "map-box" />
    )
  }
}