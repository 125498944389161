import React from 'react';
import Swiper from 'react-id-swiper';
import config from 'src/config';
import { Link } from "react-router-dom";
import { getHome } from 'api';
import './index.scss';
import { getImg, getVideo, isVisible, checkFull, throttle, scrollToBottom } from '../../utils';
import ReactPlayer from 'react-player';
import intl from "react-intl-universal";
import { Carousel } from 'antd';
import {classNames} from "react-id-swiper/lib/utils";
import right from '../../assets/images/right.svg';
import $ from 'jquery';

export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      res: {},
      mySwiper2: '',
      mySwiper3: '',
      mySwiper4: '',
      radioTextVisible: true,
      autoPlay1: false, // 视频1是否自动播放，当处于页面中时才会自动播放，离开页面即停止
      autoPlay2: false, // 视频2是否自动播放，当处于页面中时才会自动播放，离开页面即停止
      mutedPlayer1: true, // 视频默认静音
      mutedPlayer2: true,
      showCookie: true,
      showMsg: false,
      carouselAutoPlay:true
    };

    this.secondSwiper = null
  }

  componentWillUnmount() {
    window.onscroll = ''
  }

  componentDidMount() {
    scrollToBottom()
    if(localStorage.getItem('showCookie')) {
      this.setState({showCookie: false})
    }

    let msg = localStorage.getItem('actMsg');
    let tempFlag = false;
    getHome().then(res => {
      this.setState({res});

      // 推送消息
      if (!msg && res.pushContentResponse && typeof res.pushContentResponse.content !== 'undefined') {
        tempFlag = true;
        localStorage.setItem('actMsg', JSON.stringify(res.pushContentResponse))
      } else if (msg && res.pushContentResponse && typeof res.pushContentResponse.content !== 'undefined') {
        let {time, readTime} = JSON.parse(msg);
        if (readTime && time) {
          let month = new Date(readTime).getMonth();
          let date = new Date(readTime).getDate();
          let currMonth = new Date().getMonth();
          let currDate = new Date().getDate();
          if (date !== currDate) {
            tempFlag = true
          } else {
            if (month === currMonth) { // 同一个月的同一天
              // 同一时间发布的同一条消息不再显示
              tempFlag = time !== res.pushContentResponse.time;
            } else { // 不同月的同一天，显示消息
              tempFlag = true
            }
          }
        } else {
          tempFlag = true;
        }
      }

      this.setState({showMsg: tempFlag});

      // 渲染msg
      // this.showMessage(res, tempFlag)

    });

    window.onscroll = throttle(() => {
      let player1 = document.getElementById('player1');
      let player2 = document.getElementById('player2');
      let temp1 = isVisible(player1);
      let temp2 = isVisible(player2);

      this.setState({autoPlay1: temp1 });
      this.setState({autoPlay2: temp2 });
      // 进入自动播放模式，设为静音
      if(temp1) {
        this.setState({mutedPlayer1: true})
      }
      if(temp2) {
        this.setState({mutedPlayer2: true})
      }
    });

    window.onresize = throttle(() => {
      // 全屏下，放开静音。无法识别哪个视频为全屏模式，故，全部放开
      if (checkFull()) {
        this.setState({mutedPlayer1: false, mutedPlayer2: false})
      } else {
        this.setState({mutedPlayer1: true, mutedPlayer2: true})
      }
    });
  }

  prev = (type) => {
    if (type && type === 3) {
      this.state.mySwiper3.swiperRef3.slidePrev();
    } else if (type && type === 4) {
      this.state.mySwiper4.swiperRef4.slidePrev();
    } else {
      if (this.state.mySwiper2 !== null) {
        this.state.mySwiper2.swiperRef.slidePrev();
      }
    }

  };

  next = (type) => {
    if (type && type === 3) {
      this.state.mySwiper3.swiperRef3.slideNext();
    } else if (type && type === 4) {
      this.state.mySwiper4.swiperRef4.slideNext();
    } else {
      if (this.state.mySwiper2 !== null) {
        this.state.mySwiper2.swiperRef.slideNext();
      }
    }
  };

  //轮播视频播放中
  carouselVideoOnPlay=()=>{
    console.log("carouselVideoOnPlay")
    this.setState({carouselAutoPlay: false})
  }

  //轮播视频播放完成
  carouselVideoOnEnd=()=>{
    console.log("carouselVideoOnEnd")
    this.setState({carouselAutoPlay: true})
  }

  // 点击视频播放按钮，隐藏视频上的文字
  hideRadioText = () => {
    this.setState({radioTextVisible: false})
  };

  // 点击暂停按钮，显示视频上的文字
  showRadioText = () => {
    this.setState({radioTextVisible: true})
  };

  closeCookie = (flag) => {
    this.setState({showCookie: false});
    if(flag===1) {
      localStorage.setItem('showCookie', '1')
    }
  };

  showMessage = (data, flag) => {
    let ele = '';
    let newData = data ? data : this.state.res;
    if (newData) {
      const {pushContentResponse} = newData;
      let newFlag = flag ? flag : this.state.showMsg;
      if (newFlag && pushContentResponse && typeof pushContentResponse.content !== 'undefined') {
        ele = `<div className="home-message">
          <span>${pushContentResponse.content}</span>
          <span className="close-msg" style={{color: '#EFEADD'}} onClick="${()=>this.closeMsg}}"></span>
        </div>`
      }
    }
    $('.app-box').prepend(ele)
  };

  // 关闭推送消息
  closeMsg = () => {
    // 在存储当前的关闭时间
    let readTime = new Date().getTime();
    let storage = JSON.parse(localStorage.getItem('actMsg'));
    let newStorage = {...storage, readTime};
    localStorage.setItem('actMsg', JSON.stringify(newStorage));
    this.setState({showMsg: false});
  };

  render() {
    const imageSwipper1Params = {
      containerClass: 'banner1-box',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      dynamicBullets: true,
      autoplay: {
        delay: 250000,
        disableOnInteraction: false
      },
      loop: true,
      effect: 'fade',
      fade: { crossFade: true },
    };

    const imageSwipper2Params = {
      containerClass: 'banner2-box my-swipper',
      slidesPerView: 3,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      dynamicBullets: true,
      loop: true,
      // navigation: {
      //   nextEl: '.swiper-button-next .arrow-left .icon',
      //   prevEl: '.swiper-button-prev .arrow-right .icon'
      // },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false
      },
      spaceBetween: 0,
      getSwiper: swiper => {
        if (this.state && !this.state.swiper) {
          this.setState({
            mySwiper2: {
              swiperRef: swiper,
              activeIndex: swiper.activeIndex
            }
          });
        }
      }
    };

    const imageSwipper3Params = {
      containerClass: 'image-list3',
      slidesPerView: 3,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      dynamicBullets: true,
      loop: true,
      // navigation: {
      //   nextEl: '.swiper-button-next .arrow-left .icon',
      //   prevEl: '.swiper-button-prev .arrow-right .icon'
      // },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false
      },
      spaceBetween: 10,
      getSwiper: swiper => {
        if (this.state && !this.state.swiper) {
          this.setState({
            mySwiper3: {
              swiperRef3: swiper,
              activeIndex3: swiper.activeIndex
            }
          });
        }
      },
      // renderPrevButton: () => <span className="icon arrow-left swiper-button-next" onClick={this.prev1}></span>,
      // renderNextButton: () => <span className="icon arrow-right swiper-button-prev" onClick={this.next1}></span>,
    };

    const imageSwipper4Params = {
      containerClass: 'image-list3',
      slidesPerView: 3,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      dynamicBullets: true,
      loop: true,
      // navigation: {
      //   nextEl: '.swiper-button-next .arrow-left .icon',
      //   prevEl: '.swiper-button-prev .arrow-right .icon'
      // },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false
      },
      spaceBetween: 10,
      getSwiper: swiper => {
        if (this.state && !this.state.swiper) {
          this.setState({
            mySwiper4: {
              swiperRef4: swiper,
              activeIndex4: swiper.activeIndex
            }
          });
        }
      },
      // renderPrevButton: () => <span className="icon arrow-left swiper-button-next" onClick={this.prev1}></span>,
      // renderNextButton: () => <span className="icon arrow-right swiper-button-prev" onClick={this.next1}></span>,
    };

    if (!this.state.res.indexUI) {
      return <div className="home-box"></div>
    }

    const indexUI = this.state.res.indexUI // 所有图片模块
    let part1, part2, part3, part4, part5, storyDes
    indexUI.map(item => {
      if (item.id === 1) { // 首图轮播banner
        part1 = item
      } else if (item.id === 4) { // 横排多图banner
        part2 = item
      } else if (item.id === 10) { // 单图，一横三小
        part3 = item
      } else if (item.id === 11) { // 4图，一横三小
        part4 = item
      } else if (item.id === 3) { // 3图，一直两小
        part5 = item
        storyDes = item.des
      }
    })

    const part1List = part1.pcIndexBlockResponses;
    const part2List = part2.pcIndexBlockResponses;
    const part3List = part3.pcIndexBlockResponses;
    const part3SomeList = part3List.slice(1);
    const part4List = part4.pcIndexBlockResponses;
    const part4SomeList = part4List.slice(1);
    const part5List = part5.pcIndexBlockResponses;

    const videoList = this.state.res.indexVoidResponses;

    const play = (<span className="icon-play"></span>);

    const {pushContentResponse} = this.state.res;

    return (
      <div className="home-box">
        { this.state.showCookie &&
        <div className="cookie-tip">
          <div className="tip">{intl.getHTML("cookie_tip")}</div>
          <div className="close_cookie">
            <span onClick={()=>this.closeCookie(1)}><img src={right}/>{intl.get('accept_cookie')}</span>
            <span className="close icon" style={{color: '#40210F'}}  onClick={this.closeCookie}></span>
          </div>
        </div>}
        {this.state.showMsg&&pushContentResponse&&typeof pushContentResponse.content!=='undefined'&&<div className="home-message">
          <span>{pushContentResponse.content}</span>
          <span className="close-msg" style={{color: '#EFEADD'}}  onClick={this.closeMsg}></span>
        </div>}
        {/* part1 首图轮播 */}
        <Carousel effect="fade" autoplay={this.state.carouselAutoPlay} dots="swiper-pagination" speed={2000}
                  autoplaySpeed={5000}
                  dotsClass={'swiper-pagination'}>
          {
            part1List.map(banner => {
              const { url, alt } = getImg(banner.backImg);
              const {videoUrl, title} = banner.voidUrl ? getVideo(banner.voidUrl) : {};

              return (
                  <div className="item" key={banner.id}>
                    {videoUrl?(
                            <ReactPlayer className='react-player' url={videoUrl} playing={true}
                                         controls light={url} width='100%' height='56.25vw'
                                         playIcon={play} onPlay={this.carouselVideoOnPlay} onPause={this.carouselVideoOnEnd} onEnded ={this.carouselVideoOnEnd}/>
                        ):
                    <a className="swiper-slide" href={banner.url} target="_blank" rel="noopener noreferrer">
                      <div className="swiper-image" style={{backgroundImage: 'url('+url+')'}}></div>
                    </a>
                    }
                    <div className="bottom-cover"
                    style={{background: banner.isTrue===1?(banner.wordBgColor? `linear-gradient(${banner.wordBgColor})`:'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.7))'):"transparent"}}
                    ></div>
                    <div className="theme bottom" style={{color: banner.wordColor ? banner.wordColor : '#efeadd'}}>
                      <div className="sub-title">{banner.subName}</div>
                      <div className="title">{banner.title}</div>
                      <a href={banner.url} target="_blank" rel="noopener noreferrer">
                        <div className="discover" style={{color: banner.wordColor ? banner.wordColor : '#E7DED0', border: banner.wordColor ? '1px solid ' + banner.wordColor : '1px solid #efeadd'}}>
                          {banner.buttonName?banner.buttonName:intl.get('DISCOVER_NOW')}
                        </div>
                      </a>
                    </div>
                  </div>
              )
            })
          }
        </Carousel>

        {/* part2 横排多图banner */}
        <div className="part2 safe">
          <div className="main-title">{part2.name}</div>
          <div className="main-desc">{part2.des}</div>

          <div className="part2-swipper" >
            <span className="icon arrow-left swiper-button-next bigger" onClick={this.prev}></span>
            <Swiper {...imageSwipper2Params}>
              {
                part2List.map(banner => {
                  const { url, alt } = getImg(banner.backImg)
                  return (
                    <div className="item" key={banner.id}>
                      <a href={banner.url} target="_blank" rel="noopener noreferrer"><img src={url} alt={alt}/></a>
                      <div className="sub">{banner.title}</div>
                      <div className="shop">
                        <a href={banner.url} className="part2-link" target="_blank" rel="noopener noreferrer">{banner.buttonName?banner.buttonName:'SHOP NOW'}</a>
                      </div>
                    </div>
                  )
                })
              }
            </Swiper>
            <span className="icon arrow-right swiper-button-prev bigger" onClick={this.next}></span>
          </div>
        </div>

        {/* part3 单图，一横三小 */}
        <div className="part3 safe">
          {
            part3List.length ?
              <>
                <div className="top">
                  <div className="main-title">{part3.name}</div>
                  <div className="main-desc">{part3.des}</div>
                </div>
                {
                  part3List.map((item, index) => {
                    const {url, alt} = getImg(item.backImg)
                    if (!index) {
                      return (
                          <div className="big-image-box" key={item.id}>
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                              <img src={url} alt={alt}/>
                            </a>
                            <div className="theme">
                              <div className="title" style={{lineHeight: "unset", color: item.wordColor ? item.wordColor : '#E7DED0'}}>{item.title}</div>
                              <a href={item.url} target="_blank" rel="noopener noreferrer">
                                <div className="discover" style={{marginTop:"21px",color: item.wordColor ? item.wordColor : '#E7DED0', border: item.wordColor ? '1px solid ' + item.wordColor : '1px solid #efeadd'}}>{item.buttonName?item.buttonName:intl.get('DISCOVER_NOW')}</div>
                              </a>
                            </div>
                          </div>
                      )
                    }
                  })
                }

                {part3SomeList.length > 3 ?
                    (<div className="part3-swiper">
                     <span className="icon arrow-left swiper-button-next" onClick={() => {
                       this.prev(3)
                     }}></span>
                      <Swiper {...imageSwipper3Params}>
                        {
                          part3SomeList.map((item, index) => {
                            const {url, alt} = getImg(item.backImg);
                            return (
                                <a className="item" href={item.url} key={item.id} target="_blank" rel="noopener noreferrer">
                                  <img src={url} alt={alt}/>
                                  <div className="title ellipsis">{item.title}</div>
                                </a>
                            )
                          })
                        }
                      </Swiper>
                      <span className="icon arrow-right swiper-button-prev" onClick={() => {
                        this.next(3)
                      }}></span>
                    </div>) :
                    (<div className="part3-list">
                          {part3SomeList.map((item, index) => {
                            const {url, alt} = getImg(item.backImg);
                            return (
                                <a className="item" href={item.url} key={item.id} target="_blank" rel="noopener noreferrer">
                                  <img src={url} alt={alt}/>
                                  <div className="title ellipsis">{item.title}</div>
                                </a>
                            )
                          })
                          }
                        </div>
                    )
                }

              </>
              : null
          }
        </div>

        <div className="split"></div>

        {/* part4 单图，一横三小 */}
        <div className="part4 safe">
          {
            part4List.length ?
              <>
                <div className="top">
                  <div className="main-title">{part4.name}</div>
                  <div className="main-desc">{part4.des}</div>
                </div>
                {
                  part4List.map((item, index) => {
                    const { url, alt } = getImg(item.backImg)
                    if (!index) {
                      return (
                          <div className="big-image-box" key={item.id}>
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                              <img src={url} alt={alt}/>
                            </a>
                            <div className="theme">
                              <div className="title" style={{lineHeight: "unset", color: item.wordColor ? item.wordColor : '#E7DED0'}}>{item.title}</div>
                              <a href={item.url} target="_blank" rel="noopener noreferrer">
                                <div className="discover" style={{marginTop:"21px",color: item.wordColor ? item.wordColor : '#E7DED0', border: item.wordColor ? '1px solid ' + item.wordColor : '1px solid #efeadd'}}>{item.buttonName?item.buttonName:intl.get('DISCOVER_NOW')}</div>
                              </a>
                            </div>
                          </div>
                      )
                    }
                  })
                }

                {part4SomeList.length > 3 ?
                    (<div className="part4-swiper">
                      <span className="icon arrow-left swiper-button-next" onClick={() => {this.prev(4)}}></span>
                      <Swiper {...imageSwipper4Params}>
                        {
                          part4SomeList.map(item => {
                            const {url, alt} = getImg(item.backImg)
                            return (
                                <a className="item" href={item.url} key={item.id} target="_blank" rel="noopener noreferrer">
                                  <img src={url} alt={alt}/>
                                  <div className="title ellipsis">{item.title}</div>
                                </a>
                            )
                          })
                        }
                      </Swiper>
                      <span className="icon arrow-right swiper-button-prev" onClick={() => {this.next(4)}}></span>
                    </div>) :
                    (
                        <div className="part4-list">
                          {part4SomeList.map((item, index) => {
                            const {url, alt} = getImg(item.backImg);
                            return (
                                <a className="item" href={item.url} key={item.id} target="_blank" rel="noopener noreferrer">
                                  <img src={url} alt={alt}/>
                                  <div className="title ellipsis">{item.title}</div>
                                </a>
                            )
                          })
                          }
                        </div>
                    )
                }
              </>
              : null
          }
        </div>
        <div className="split"></div>

       {/* 视频*/}
        {videoList.length&&(<div className="part6 safe">
          {
            videoList.map((item, index) =>{
              if(index>1) {
                return
              }
              const { url, alt } = getImg(item.image);
              const { videoUrl, title } = getVideo(item.voidUrl);
              let playIcon = (<div className="video-middle"><span className="icon-play"></span><span className="video-title">{item.title}</span></div>);
              return (
                  <div className="big-video-box" key={item.id}>
                    {index > 0 && (<div className="top">
                          <div className="title">
                            {intl.get('STORIES')}
                          </div>
                          <div className="main-desc">{storyDes}</div>
                        </div>
                    )}
                    <div className='player-wrapper player-wrapper-text' style={{height: '652.5px'}}>
                      {index>0&&this.state.radioTextVisible ? (
                          <div className="text-con">
                            <span className="title1">{item.title}</span>
                            <span className="sub-title">{item.subName}</span>
                            <a href={item.link} target="_blank" rel="noopener noreferrer"><div className="go-story">{item.buttonName}</div></a>
                          </div>
                      ):''}
                      {index === 0 && <a href={item.link} className="videoLink" target="_blank" rel="noopener noreferrer"><ReactPlayer className='react-player' url={videoUrl}
                                                   playing={this.state.autoPlay1}
                                                   light={this.state.autoPlay1 ? false : url}
                                                   width='100%'
                                                   height='100%' playIcon={index < 1 ? playIcon : play}
                                                   id={`player${index + 1}`}
                                                   onPlay={this.hideRadioText} onEnded={this.showRadioText}/></a>}
                      {index === 1 && <a href={item.link} className="videoLink" target="_blank" rel="noopener noreferrer"><ReactPlayer className='react-player' url={videoUrl}
                                                   playing={this.state.autoPlay2}
                                                   light={this.state.autoPlay2 ? false : url}
                                                   width='100%'
                                                   height='100%' playIcon={index < 1 ? playIcon : play}
                                                   id={`player${index + 1}`}
                                                   onPlay={this.hideRadioText} onEnded={this.showRadioText}/></a>}

                    </div>
                  </div>
              )
            })
          }
        </div>)}

        {/* part5 3图，一直两小 */}
        <div className="part5 safe">
          {
            part5List.map((item, index, arr) => {
              const { url, alt } = getImg(item.backImg)
              if (index===arr.length-1) {
                return (
                  <div className="left" key={item.id}>
                    <div className="img-box">
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <img src={url} alt={alt}/>
                      </a>
                      <span className="title" style={{color: item.wordColor ? item.wordColor : '#E7DED0'}}>{item.title}</span>
                      <span className="sub-title" style={{color: item.wordColor ? item.wordColor : '#E7DED0'}}>{item.subName}</span>
                      <a className="link" href={item.url} target="_blank" rel="noopener noreferrer"><div className="go-story" style={{color: item.wordColor ? item.wordColor : '#E7DED0', border: item.wordColor ? '1px solid ' + item.wordColor : '1px solid #EFEADD60'}}>{item.buttonName}</div></a>
                    </div>
                  </div>
                )
              }
            })
          }

          <div className="right">
            {
              part5List.map((item, index, arr) => {
                const { url, alt } = getImg(item.backImg)
                if (index<arr.length-1) {
                  return (
                    <div className="img-box" key={item.id}>
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <img src={url} alt={alt}/>
                      </a>
                      <span className="title" style={{color: item.wordColor ? item.wordColor : '#E7DED0'}}>{item.title}</span>
                      <span className="sub-title" style={{color: item.wordColor ? item.wordColor : '#E7DED0'}}>{item.subName}</span>
                      <a className="link" href={item.url} target="_blank" rel="noopener noreferrer"><div className="go-story" style={{color: item.wordColor ? item.wordColor : '#E7DED0', border: item.wordColor ? '1px solid ' + item.wordColor : '1px solid #EFEADD60'}}>{item.buttonName}</div></a>
                    </div>
                  )
                }
              })
            }
          </div>

        </div>

        <div className="top-container">
          <div className="top-box" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
            <div className="arrow">
              <div className="arrow-up icon"></div>
            </div>
            <div className="txt">{intl.get('Top')}</div>
          </div>
        </div>

      </div>
    )

  }
}
