import React from 'react';
import { getImg, getVideo, isVisible, checkFull, throttle, scrollToBottom } from 'utils'
import { getStoryList } from 'api';
import { Link } from "react-router-dom";
import './index.scss';
import {format} from 'date-fns';
import intl from 'react-intl-universal';
import ReactPlayer from 'react-player';
import $ from "jquery";

export default class Stories extends React.Component {
  constructor() {
    super()
    this.state = {
      list: [],
      limit: 6,
      showMoreBtn: true
    }
  }

  loadMore = () => {
    if (this.state.limit < this.state.list.length) {
      let newLimit = this.state.limit + 6;
      this.setState({limit: newLimit});
      if (newLimit >= this.state.list.length) {
        this.setState({showMoreBtn: false})
      }
    } else {
      this.setState({showMoreBtn: false})
    }

  };

  componentDidMount() {
    getStoryList()
      .then(res => {
        this.setState({ list: res });

        if (!res || res.length < 4) {
          this.setState({ showMoreBtn: false })
        } else {
          this.setState({ showMoreBtn: true })
        }

        window.onscroll = throttle(() => {
          let list = this.state.list;
          list.map((item,i)=>{
            let player1 = document.getElementById(i);
            let temp = isVisible(player1);
            item.autoPlay = temp;

            // 进入自动播放模式，设为静音
            if(temp) {
              item.mutedPlayer = true;
            }
          });
          this.setState({list})
        });

        window.onresize = throttle(() => {
          // 全屏下，放开静音。无法识别哪个视频为全屏模式，故，全部放开
          let list = this.state.list;
          if (checkFull()) {
            list.map((item,i)=>{
              item.mutedPlayer = false
            })
          } else {
            list.map((item,i)=>{
              item.mutedPlayer = true
            })
          }
          this.setState({list})
        });

      })
  }

  componentWillUnmount() {
    window.onscroll = '';
    window.onresize = '';
    scrollToBottom()
  }


  render() {
    const playIcon = (<span className="icon-play"></span>);
    return (
      <div className="stories-box">
        <div className="top">
          <span className="main-title">{intl.get('STORIES')}</span>
        </div>

        {/* 故事列表 */}
          {
              this.state.list.map((item, index, arr) => {
                  const {url, alt} = getImg(item.coverImageUrl);
                  const {videoUrl, title} = item.voidUrl ? getVideo(item.voidUrl) : {};
                  const time = format(item.date, 'yyyy/MM/dd');
                if (index < this.state.limit) {
                  return (
                      <div className={`part part-${index}`} key={item.id}>
                        {item.voidUrl? (
                            <ReactPlayer className='react-player' id={index}
                                         light={item.autoPlay ? false :url} playIcon={playIcon}
                                         controls url={videoUrl}
                                         playing={item.autoPlay} muted={item.mutedPlayer}

                            />
                        ):<img src={url} alt={alt}/>}
                        <div className="part-desc">
                          <div className="time">{time}</div>
                          <div className="title">{item.title}</div>
                          <div className="tip">{item.subtitle}</div>
                          <Link className="add" to={`/story/detail?id=${item.id}`}>
                            {/*<span className="plus-icon"></span>*/}
                            <span className="aaSquarePlus">+</span>
                          </Link>
                        </div>
                        {index < arr.length - 1 && (index < this.state.limit - 1) && (<div className="line"></div>)}
                      </div>
                  )
                }
              })
          }

          {this.state.showMoreBtn&&<div className="load-more" onClick={this.loadMore}>
              {intl.get('LOAD_STORIES')}
          </div>}

      </div>
    )
  }
}