import axios from 'axios';
import config from 'src/config'

const baseURL = process.env.NODE_ENV === 'development' ? '/api' : config.BASE_URL

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  }
})

instance.interceptors.request.use(
  config => {
    // 全局国际化接口处理
    const spliceStr = config.url.indexOf('?') === -1 ? '?' : '&'
    const url = `${config.url}${spliceStr}type=${window.localStorage.getItem('lang')}`
    return { ...config, url }
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  config => {
    // code为200
    const { status, data } = config
    if (status === 200) {
      return Promise.resolve(data)
    }
  },
  error => {
    const res = error.response

    // 用户信息失效，重定向到登录页面
    return Promise.reject(res)
  }
)

export default instance