import React from 'react';
import {getPrivacy} from 'api';
import './index.scss';
import intl from 'react-intl-universal';

export default class Stories extends React.Component {
  constructor() {
    super();
    this.state = {
      detail: ''
    }
  }

  componentDidMount() {
    sessionStorage.setItem('modal', true);
    getPrivacy().then(res => {
      let lang = parseInt(localStorage.getItem('lang'));
      switch (lang) {
        case 1:
          this.setState({detail: res.data.content});
          break;
        case 2:
          this.setState({detail: res.data.twContent});
          break;
        case 3:
          this.setState({detail: res.data.enContent});
          break;
        case 4:
          this.setState({detail: res.data.jpContent});
          break;
        default:
          this.setState({detail: res.data.content});
          break;
      }
    });
  }

  render() {
    const { detail } = this.state;
    return (
      <div className="privacy-box">
        <div className="privacy-head">
          <span className="privacy-title">{intl.get('PRIVACY_NOTICE')}</span>
          <a className="privacy_CLOSE" href="javascript:history.go(-1)"><span></span></a>
        </div>
        <div className="privacy-content">
          <div className="desc" dangerouslySetInnerHTML={{ __html: detail }}></div>
        </div>
      </div>
    )
  }
}
