import React from 'react';
import {getImg, getVideo} from 'utils';
import {Link} from "react-router-dom";
import './index.scss';
import intl from 'react-intl-universal';
import ReactPlayer from 'react-player';

export default class Craftmanship extends React.Component {
  constructor() {
    super();
    this.state = {
      list: [],
    }
  }

  componentDidMount() {
    // console.log('props',this.props)
    let imgList = [];
    for (let i = 0; i < 9; i++) {
      imgList.push({id: i})
    }
    this.setState({list: imgList})
  }

  componentDidUpdate() {
    // console.log('props', this.props)
  }


  render() {
    const {data} = this.props;
    const {url, alt} = data.craftImageResponses ? getImg(data.craftImageResponses[data.craftImageResponses.length - 1].imageUrl) : {
      url: '',
      alt: ''
    };
    const {craftPathResponses} = data;
    // 播放按钮
    const play = (<span className="icon-play"></span>);
    return (
        <div className="craftmanship-main">
          <div className="sub-link">
            <div className="link-con">
              {craftPathResponses && craftPathResponses.map((item, i) =>
                  <a href={item.path} key={i}>
                    <div className="item">{item.title}</div>
                  </a>
              )}
            </div>
          </div>
          <div className="img-flex">
            {data.craftImageResponses && data.craftImageResponses.map((item, index) => {
              let videoUrl = '';
              if (index < 9) {
                let fileTitle = item.voidUrl ? item.voidUrl[0].title.toLowerCase() : '.png';

                let {url: url1, alt: alt1} = getImg(item.imageUrl);
                if (item.voidUrl) {
                  videoUrl = getVideo(item.voidUrl).videoUrl;
                }
                return (<div className="img-item" key={index} style={{position: index>-1&&index<3?'relative': ''}}>
                  {(fileTitle.indexOf('.png') > -1 || fileTitle.indexOf('.jpg') > -1 || fileTitle.indexOf('.jpeg') > -1 || fileTitle.indexOf('.bmp') > -1) ?
                      <img src={url1} alt={alt1}/>
                      :
                      <ReactPlayer className='video-player' url={videoUrl} playing={true}
                                   width='100%' height='100%' muted={true}
                                   loop={true} playIcon={play}/>

                  }

                </div>)
              }
            })
            }
            {/* {url && <img src={url} alt={alt}/>} */}
          </div>

          <div className="text">
            <div className="title">{data.title}</div>
            <div className="content">
              <div dangerouslySetInnerHTML={{__html: data.content}}></div>
            </div>
          </div>

          <div className="bottom-img-panel">
            <div className="img-left">CRAFTMANSHIP</div>
            <div className="img-right">
              {url && <img src={url} alt={alt}/>}
            </div>
          </div>
        </div>
    )
  }
}
