import React from 'react';
import {getBrandBook, getBrandStory, getBrandCraft} from 'api';
import {getQuery, scrollToBottom} from 'utils';
import {Link} from "react-router-dom";
import {Tabs} from 'antd';
import './index.scss';
import intl from 'react-intl-universal';
import BrandStory from '../brandStory';
import Craftmanship from '../craftmanship';
import Book from '../book';

const {TabPane} = Tabs;
export default class TabContainer extends React.Component {
  constructor() {
    super()
    this.state = {
      list: [],
      tab: '2',
      craftRes: {},
      bookRes: {},
      storyRes: {},
      defaultActiveKey: '2'
    }
  }

  //组件更新时被调用
  componentWillReceiveProps(nextProps){
    // 获取浏览器地址携带的tab参数
    const currentTab = getQuery('tab');
    if (currentTab) {
      this.setState({ defaultActiveKey: currentTab });
      document.getElementById('root').scrollIntoView(true);
    }
  }

  componentDidMount() {
    scrollToBottom(true);
    this.getBrandCraft();
    this.getBrandBook();
    this.getBrandStory();
    // 获取浏览器地址携带的tab参数
    const currentTab = getQuery('tab');
    if(currentTab) {
      this.setState({defaultActiveKey: currentTab});
    }
  }

  // 查询品牌故事
  getBrandStory = () => {
    getBrandStory().then(res => {
      res.imageResponses = res.imageResponses.reverse();
      // res.intro = `<span>“</span>${res.intro}<span>”</span>`;
      // console.log(res, 'getBrandStory')
      this.setState({storyRes: res})
    })
  };

  // 查询品牌书
  getBrandBook = () => {
    getBrandBook().then(res => {
      // console.log(res, 'getBrandBook')
      this.setState({bookRes: res})
    })
  };

  // 查询品牌工艺
  getBrandCraft = () => {
    getBrandCraft().then(res => {
      // console.log(res, 'getBrandCraft')
      this.setState({craftRes: res})
    })
  };

  handleChange = (key) => {
    this.setState({tab: key, defaultActiveKey: key})
    this.props.history.push('/brandStory?tab='+key)
  };

  render() {
    return (
        <div className="tab-con">
          <Tabs activeKey={this.state.defaultActiveKey} size="large" animated={false}
                tabBarStyle={{textAlign: 'center', borderBottom: 'none', marginBottom: '44px'}} onChange={this.handleChange}>
            <TabPane tab={intl.get('BRAND_STORY')} key="3">
              <BrandStory tab={this.state.tab} data={this.state.storyRes}/>
            </TabPane>
            <TabPane tab={intl.get('THE_CRAFTMANSHIP')} key="2">
              <Craftmanship tab={this.state.tab} data={this.state.craftRes}/>
            </TabPane>
            <TabPane tab={intl.get('THE_BOOK')} key="1">
              <Book tab={this.state.tab} data={this.state.bookRes}/>
            </TabPane>
          </Tabs>
        </div>
    )
  }
}