import React from 'react';
import {getImg, getVideo, isVisible, checkFull, throttle} from 'utils';
import './index.scss';
import ReactPlayer from 'react-player';
import { es } from 'date-fns/locale';
import $ from 'jquery';

export default class BrandStory extends React.Component {
  constructor() {
    super();
    this.state = {
      // data: {},
      // imglist: [],
      // autoPlay1: false,
      // mutedPlayer1: true, // 视频默认静音
    }
  }

  componentDidMount() {
    console.log("componentDidMount")
    // $('.aniimg').addClass('-on')
    document.getElementById('root').scrollIntoView(true);
    $('.aniimg').removeClass('-on')
    $('.aniimg').addClass('-on')
    $('.text-content').addClass('fadeIn')
    // this.setState({data: this.props.data});
    // const imglist = this.props.data && this.props.data.imageResponses ? this.props.data.imageResponses.reverse() : [];
    // this.setState({imglist})
    /*window.onscroll = throttle(() => {
      let player1 = document.getElementById('player1');
      if (!player1) {
        return
      }
      let temp1 = isVisible(player1);

      this.setState({autoPlay1: temp1});
      // 进入自动播放模式，设为静音
      if (temp1) {
        this.setState({mutedPlayer1: true})
      }
    });

    window.onresize = throttle(() => {
      // 全屏下，放开静音。无法识别哪个视频为全屏模式，故，全部放开
      if (checkFull()) {
        this.setState({mutedPlayer1: false})
      } else {
        this.setState({mutedPlayer1: true})
      }
    });*/
  }

  componentDidUpdate(){
    // $('.aniimg').addClass('-on')
    console.log("componentDidUpdate")
  }

  componentWillUnmount() {
    // window.onscroll = ''
  }


  render() {
    let url1, url2, url3;
    const {data} = this.props;
    console.log(123123123,this.props)
    const imglist = data.imageResponses ? data.imageResponses : [];
    imglist.map((item, i) => {
      switch (i) {
        case 0:
          url1 = getImg(item.imageUrl).url;
          break;
        case 1:
          url2 = getImg(item.imageUrl).url;
          break;
        case 2:
          url3 = getImg(item.imageUrl).url;
          break;
        default:
        break;
      }
    });

    let newImgGroupList = [], imgGroupCache = [];
    let newImgList = JSON.parse(JSON.stringify(imglist)).splice(3).reverse();
    // console.log(123123123,newImgList)
    newImgList.map((item, index, arry) => {
      item.imageUrl = getImg(item.imageUrl).url;
      item.voidUrl = item.voidUrl ? getVideo(item.voidUrl).videoUrl : false;
      imgGroupCache.push(item);
      let rank = index + 1
      if (rank % 3 === 2) {
        newImgGroupList.push(imgGroupCache)
        imgGroupCache = []
      } else if(rank % 3 === 0) {
        if (rank !== arry.length - 1) {
          newImgGroupList.push(imgGroupCache)
          imgGroupCache = []
        }
      } else if (rank % 3 === 1) {
        if (rank === arry.length) {
          newImgGroupList.push(imgGroupCache)
          imgGroupCache = []
        }
      }
    })
    // console.log(newImgGroupList)

    const play = (<span className="icon-play"></span>);
    $('.aniimg').removeClass('-on')
    $('.aniimg').addClass('-on')
    $('.text-content').addClass('fadeIn')

    return (
        <div className="brand-story-main">
          <div className="big-img-panel">
            <div className="first-img aniimg">
              <img src={url3}/>
              <div className="text-content" dangerouslySetInnerHTML={{__html: data.intro}}></div>
            </div>
            <div className="first-img two-article">
              <div className="flex">
                <img src={url2}/>
                <div className="flex-left">
                  <div className="sub-flex">
                    <div className="text-content2 scroll" dangerouslySetInnerHTML={{__html: data.recordedUtterance}}></div>
                    <div className="people-con">
                      <img src={url1} width="100%" height="100%"/>
                    </div>
                  </div>
                  <div className="text-content3 scroll" dangerouslySetInnerHTML={{__html: data.introduction}}></div>
                </div>
              </div>

            </div>
          </div>


          {
            newImgGroupList.map((group, i) => {
                return (
                  <div className="middle-panel" key={'group'+i}>
                    {
                      group.map((item, index, arry) => {
                        if (arry.length === 1) {
                          return (
                            <div className="all">
                              <div className="title">
                                {item.title&&<span className="year">{parseInt(item.title)}</span>}
                                {item.title ? item.title.substring(4) : ''}
                              </div>
                              {
                                item.voidUrl ?
                                (<div className="img-con"><ReactPlayer className='react-player' url={item.voidUrl} playing={true} controls light={item.imageUrl} width='100%' height='732px' playIcon={play}
                                /></div>)
                                :
                                ( item.link ? (
                                  <a target="_blank" href={item.link}><img src={item.imageUrl}/></a>
                                ):(
                                  <img src={item.imageUrl}/>
                                ))
                              }
                            </div>
                          )
                        } else if (arry.length === 2) {
                          return (
                            <div className="left">
                              <div className="title">
                                {item.title&&<span className="year">{parseInt(item.title)}</span>}
                                {item.title ? item.title.substring(4) : ''}
                              </div>
                              {
                                item.voidUrl ?
                                (<div className="img-con"><ReactPlayer className='react-player' url={item.voidUrl} playing={true} controls light={item.imageUrl} width='100%' height='351px' playIcon={play}
                                /></div>)
                                :
                                ( item.link ? (
                                  <a target="_blank" href={item.link}><img src={item.imageUrl}/></a>
                                ):(
                                  <img src={item.imageUrl}/>
                                ))
                              }
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                )
            })
          }

        </div>
    )
  }
}