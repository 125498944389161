import React from 'react';
import './index.scss';
import {emit} from '../../emit.js';
import { selectLanguage, getLang, getLangList } from 'api';
import { CloseOutlined } from '@ant-design/icons';
import $ from 'jquery'

export default class SelectLang extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: window.localStorage.getItem('lang'),
      transtionVisible: true,
      langMap: [
        {
          text: '<span>简</span><span></span><span>中</span>',
          lang: '1'
        },
        {
          text: '<span>繁</span><span></span><span>中</span>',
          lang: '2'
        },
        {
          text: '<span>ENGLISH</span>',
          lang: '3'
        },
        {
          text: '<span>日</span><span>本</span><span>語</span>',
          lang: '4'
        },
      ]
    }
  }

  componentDidMount() {
    getLangList().then(res=>{
      if(res.data&&res.data.length) {
        let langMap = [];
        res.data.map(item => {
          if (item.isValid === 1) {
            switch (item.lang) {
              case 'zh_CN':
                langMap.push({
                  text: '<span>简</span><span></span><span>中</span>',
                  lang: '1'
                });
                break;
              case 'zh_TW':
                langMap.push({
                  text: '<span>繁</span><span></span><span>中</span>',
                  lang: '2'
                });
                break;
                case 'en_US':
                langMap.push({
                  text: '<span>ENGLISH</span>',
                  lang: '3'
                });
                break;
                case 'ja_JP':
                langMap.push({
                  text: '<span>日</span><span>本</span><span>語</span>',
                  lang: '4'
                });
                break;
            }
          }
        });
        this.setState({langMap})
      }
    });
    // 获取语言
    getLang().then(res=>{
      /*if(res.data === "zh_CN") {
        this.setState({lang: '1'});
        this.changeLang('1')
      } else {
        this.setState({lang: '3'});
        this.changeLang('3')
      }*/
      switch (res.data) {
        case 'zh_CN':
          this.setState({lang: '1'});
          // this.changeLang('1')
          this.changeLang('1')
          break;
        case 'zh_TW':
          this.setState({lang: '2'});
          this.changeLang('2')
          break;
        case 'en_US':
          this.setState({lang: '3'});
          this.changeLang('3')
          break;
        case 'ja_JP':
          this.setState({lang: '4'});
          this.changeLang('4')
          break;
        default:
          this.setState({lang: '3'});
          this.changeLang('3')
          break;
      }

      const lang = this.state.lang;
      $('#center-box').mouseover((e)=> {
        this.setState({lang: ''});
      });
      $('#center-box').mouseleave((e)=> {
        this.setState({lang: lang});
      })
    })
  }

  changeLang = (lang, close) => {
    if (close) {
      setTimeout(() => {
        this.props.close()
      }, 300)
    }
    let langStr = '';
    let changeLangStr='';

    switch (lang) {
      case '1':
        changeLangStr = 'zh-CN';
        langStr = 'zh_CN';
        break;
      case '2':
        changeLangStr = 'zh-TW';
        langStr = 'zh_TW';
        break;
      case '3':
        changeLangStr = 'en-US';
        langStr = 'en_US';
        break;
      case '4':
        changeLangStr = 'ja-JP';
        langStr = 'ja_JP';
        break;
      default:
        changeLangStr = 'en-US';
        langStr = 'en_US';
        break;
    }
    
    selectLanguage(langStr).then(res => {
      setTimeout(()=>{
      this.setState({ transtionVisible: true });
      },300)
      window.localStorage.setItem('firstInitLang', true);
      if (lang !== window.localStorage.getItem('lang')) {
        window.localStorage.setItem('lang', lang);
        window.location.reload()
      }
      emit.emit('change_language', changeLangStr);
    });
  };

  handleChangelang = lang => {
    this.setState({ lang, transtionVisible: false });
    this.changeLang(lang, true)

  };

  closeModal = () => {
    this.setState({transtionVisible:false});
    setTimeout(()=>{
      this.setState({ transtionVisible: true });
    },300)
    this.props.close()
  };

  render() {
    const {transtionVisible} = this.state;
    return (
      <div className={`modal-box ${transtionVisible ? 'transtion-show' : 'transtion-hide'} ${this.props.visible ? '' : 'hide'}`}>

        <div className="box">
          <div className="header">
            <span className="title">请选择您的语言 | Select Your Language</span>
            <span className="close" onClick={this.closeModal}>
              <CloseOutlined style={{ fontSize: '16px', color: '#6A3906' }} />
            </span>
          </div>

          <div className="content">
            <div className="center-box" id="center-box">
              {
                this.state.langMap.map(item => {
                  const lang = this.state.lang;
                  return (
                    <div className={`selected-line ${item.lang === lang? 'selected':''}`} id="lang-item" key={item.lang}>
                      {/*{item.lang === lang ? <div className="line"></div> : null}*/}
                      <div
                        className={`
                          lang-item
                          ${item.lang === '3' ? 'lang-english' : 'cn'}
                          ${item.lang === lang ? 'active' : ''}
                        `}
                        onClick={() => this.handleChangelang(item.lang)}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      >
                      </div>
                      {/*{item.lang === lang ? <div className="line"></div> : null}*/}
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

      </div>
    )
  }
}
