import React from 'react';
import './index.scss';
import logo from 'assets/images/LOGO.png';
import { Link, withRouter } from "react-router-dom";
import NavDetail from './NavDetail';
import SelectLang from '@/SelectLang';
import login_zhifubao from 'assets/images/login_zhifubao.png';
import login_wechat from 'assets/images/login_wechat.png';
import login_facebook from 'assets/images/login_facebook.png';
import onlineService from 'assets/images/online-service-message.png';

import {
  getHead, login, getList, getReservationList,
  getCartNum1, removeFromCart1, cartToWish1, cartToBoutique1, boutiqueToCart1, getUserInfo,getWishList,removeFromWish
} from 'api';
import { Dropdown } from 'antd';
import intl from 'react-intl-universal';
import $ from 'jquery';
import { CloseOutlined, HeartOutlined, HeartFilled } from '@ant-design/icons';
import { getImg, getCookie } from '../../utils';
import userImg from '../../assets/images/user.svg';
import proxy from '../../setupProxy'
class CommonHeader extends React.Component {

  constructor() {
    super()

  }

  state = {
    loading: true,
    locationVisible: false,
    langVisible: false,
    lang: null,

    searchVisible: false,
    searchValue: '',

    shoppingBagVisible: false,

    navList: [],
    loginLink: '',
    registerLink: '',
    subNavItem: [],
    subNavVisible: false,
    visible: false, // 登录窗口可见
    isLogin: true, // 登录还是注册
    userName: '',
    pwd: '',
    userNameFlag: false,
    pwdFlag: false,
    bagVisible: false,
    reservationVisible: false,
    loginErr: '',
    cartList: [],
    cartNum: '0',
    reservationList: [],
    reservationNum: '0',
    womenUrl: '',
    menUrl: '',
    returnAndExchangeUrl: 'https://www.shiatzychen.com/content/faq?id=26&nowIndex=4',
    deliveryTimeAndCostUrl: 'https://www.shiatzychen.com/content/faq?id=26&nowIndex=2',
    status: 1, // 登录状态：0 已登录， 1 未登录
    userInfo: {},
    cartTotalPrice: 0, // 购物车总价
    errTip: { title: '', show: false },
    wishList:[], //愿望清单
    wishNum:0
  };

  doSearch = value => {
    if (this.state.searchValue) {
      window.location.href = `https://www.shiatzychen.com/goods/search?goodsName=${this.state.searchValue}`
    }
  };

  showOpt = value => {
    switch (value) {
      case 1:
        this.setState({ shoppingBagVisible: true });
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      default:
        break;
    }
  };

  // 获得购物车和精品店列表
  getList = () => {
    this.getCartList();
    this.getReservationList();
    this.getWishList();
  };


  componentDidMount() {
    // 百度统计
    setTimeout(() => {
      var _hmt = _hmt || [];
      (function () {
        //每次执行前，先移除上次插入的代码
        document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?1c7b6bb5f759b2ffda106e3d6edd5869";
        hm.id = "baidu_tj"
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
      this.props.history.listen((location, action) => {
        // location is an object like window.location
        // console.log(action, location.pathname, location.state)
        // debugger
        window._hmt.push(['_trackPageview', location.pathname]);
      });
    });

   // console.log('firstInitLang:', localStorage.getItem('firstInitLang'))
    this.setState({ langVisible: !(localStorage.getItem('firstInitLang')) })
    let status = localStorage.getItem('userInfo') ? 0 : 1;

    this.setState({ status })
    this.getList();

    getHead().then(res => {
      res.navigationForPcResponses.map(item => {
        if (item.id === 2) {
          this.setState({ womenUrl: item.path })
        }
        if (item.id === 3) {
          this.setState({ menUrl: item.path })
        }
      });
      this.setState({
        loading: false,
        lang: res.languageTypeResponses,
        navList: res.navigationForPcResponses.reverse(),
        loginLink: res.navigationResponses[0].link,
        registerLink: res.navigationResponses[1].link,
      })
    });
    $('.header-box').data('size', 'big');
    $(document).scroll(() => {
      let scrollTop = $(document).scrollTop();
      if (scrollTop > 1) {
        if ($('.header-box').data('size') === 'big') {
          $('.app-box').stop().animate({ paddingTop: '97px' }, 500);
          $('.header-box').stop().animate({ height: '97px' }, 500);
          $('.header-top').stop().animate({ height: '49px' }, 500);
          $('.logo-con').stop().animate({ top: '26px' }, 500);
          $('.header-logo').stop().animate({ width: '260px', height: '19.5px' }, 500);
          $('.search-box ').stop().animate({ top: '97px' }, 500);
          $('.header-box').data('size', 'small');
        }
      } else {
        if ($('.header-box').data('size') === 'small') {
          $('.app-box').stop().animate({ paddingTop: '141px' }, 500);
          $('.header-box').stop().animate({ height: '141px' }, 500);
          $('.header-top').stop().animate({ height: '93px' }, 500);
          $('.logo-con').stop().animate({ top: '29px' }, 500);
          $('.header-logo').stop().animate({ width: '372px', height: '28px' }, 500);
          $('.search-box ').stop().animate({ top: '141px' }, 500);
          $('.header-box').data('size', 'big');
        }
      }
    })
  }

  onInputChange = e => {
    this.setState({ searchValue: e.target.value })
  };

  navHover = subNavItem => {
    this.setState({
      // subNavVisible: true,
      subNavItem
    })
  };

  hideSubNav = path => {
    console.log(this.props.location)
    console.log(path)
    if (path.endsWith(this.props.location.pathname + this.props.location.search))
      window.location.reload()
    this.setState({
      subNavVisible: false,
    })
  };

  handleNavVisibleChange = flag => {
    console.log("navVisable", flag)
    // if (this.state.subNavVisible)
    //   return
    this.setState({ subNavVisible: flag });
  };

  handleVisibleChange = flag => {
    this.setState({ visible: flag, isLogin: true });
  };

  // 登录窗口
  openLoginModal = () => {
    const loginModal = (
      <div className="opt-box login-box">
        <div className="top">
          <span className="iconfont icon-zhanghu"></span>
          <span className="opt-title">{intl.get('Login_in') + '|' + intl.get('Register')}</span>
        </div>
        <div className="content">
          <div className="form">
            <div className="head">{this.state.isLogin === true ? intl.get('Login_in') : intl.get('Register')}</div>
            <div className="form-item">
              <div className='p'>
                {intl.get('login_tip')}
              </div>
              <input type="text" placeholder={intl.get('username_field')}
                onChange={e => this.setState({ userName: e.target.value })} />
              <div
                className="err-tip">{this.state.userNameFlag && intl.get('username_field_required')}</div>
              <input className="mgt-2" type="password" placeholder={intl.get('Password')}
                onChange={e => this.setState({ pwd: e.target.value })} />
              <div className="err-tip">{this.state.pwdFlag && intl.get('Password_required')}</div>


              <div style={{width: "100%"}}>
                <p style={{float:"left",marginLeft: "100px",width:"20px",height:" 20px"}}>
                  <a  href="/passport/alipay/login">
                    <img style={{width:"100%"}} src={login_zhifubao} alt="" />
                  </a>
                </p>
                <p style={{float:"left",marginLeft: "25px",width:"20px",height:" 20px"}}>
                  <a href="/passport/wechat/login">
                    <img style={{width:"100%"}} src={login_wechat} alt="" />
                  </a>
                </p>
                  <p style={{float:"left",marginLeft: "25px",width:"20px",height:" 20px"}}>
                      <a href="/passport/facebook/login">
                          <img style={{width:"100%"}} src={login_facebook} alt="" />
                      </a>
                  </p>
              </div>
              <br/>

              <a href="/passport/toForget">
                <div className="forget-tip">{intl.get('Forget_Password')}</div>
              </a>
              <div className="btn" onClick={this.login}>{intl.get('Login_in')}</div>
              {this.state.loginErr && <div className="err-tip-bottom">{this.state.loginErr}</div>}
              {/*<div className="btn">{intl.get('Register')}</div>*/}
              <a href="/passport/toRegister">
                <div className="forget-tip mgb-16">
                  <span>{intl.get('register_tip')}</span>
                  <span className="register_go_tip">{intl.get('register_go_tip')}</span>
                </div>
              </a>
            </div>

          </div>
        </div>

      </div>
    );

    return loginModal
  };

  login = () => {
    if (!this.state.userName) {
      this.setState({ userNameFlag: true });
      return
    }
    if (!this.state.pwd) {
      this.setState({ pwdFlag: true });
      return
    }
    login(this.state.userName, this.state.pwd).then(res => {
      // console.log('login:', res)
      if (res.code === 200) {
        this.setState({ visible: false, loginErr: '' });
        this.setState({ status: 0 });
        this.getList();
        this.getUserInfo();

      } else {
        this.setState({ status: 1 });
        this.setState({ loginErr: res.message });
      }

    })
  };

  async getUserInfo() {
    const { code, data, message } = await getUserInfo()
    if (code === 200) {
      this.setState({ userInfo: data.accountDomain });
      localStorage.setItem('userInfo', JSON.stringify(data))
    } else {
      this.err(message);
    }
  }

  // 获取购物车列表
  getCartList() {
    getList().then(res => {
      // console.log('购物车：', typeof res)
      if (res && res.length) {
        let num = 0;
        let price = 0;
        res.map(item => {
          num += item.num;
          price += item.subTotal;
        });
        this.setState({ cartList: res, cartNum: num, cartTotalPrice: price })
      } else {
        this.setState({ cartList: [], cartNum: 0, cartTotalPrice: 0 })
      }
    })
  };

  // 获取预约列表
  getReservationList() {
    getReservationList().then(res => {
      // console.log('预约：', res)
      if (res && res.length && typeof res === 'object') {
        let num = 0;
        res.map(item => {
          num += item.num
        });
        this.setState({ reservationList: res, reservationNum: num })
      } else {
        this.setState({ reservationList: [], reservationNum: 0 })
      }
    })
  };


  getWishList(){
    getWishList().then(res => {
      if (res && res.length && typeof res === 'object') {
        this.setState({wishList:res,wishNum:res.length})
      }else {
        this.setState({wishList:[], wishNum: 0 })
      }
    })
  };

  // 获取购物车和精品店下拉框商品数量
  async getCartNum(type = 1) {
    let cartNum = await getCartNum1[this.state.status](type);
    // let boutiqueNum = await getCartNum1[this.state.status](type);
    if (type === 1) {
      this.setState({ cartNum: cartNum.data })
    } else if (type === 3) {
      this.setState({ reservationNum: cartNum.data })
    }
    // this.setState({cartNum: cartNum.data, reservationNum: boutiqueNum.data})
  };

  // 移除购物车
  removeFromCart(id) {
    removeFromCart1[this.state.status](id).then(res => {
      if (res.code === 200) {
        this.getList()
      } else {
        this.err(res.message);
      }
    })
  };


  removeFromWish(item) {
    let wishParams = {}
    wishParams.itemId = item.goodsItemDomain.id+"";
    wishParams.sizeId = item.sizeDomain.id+"";
    wishParams.skuId = item.skuDomain.id+"";
    wishParams.type =2;
    console.log(wishParams);
     removeFromWish[this.state.status](wishParams).then(res=>{
       console.log(res);
       if (res.code === 200) {
         this.getList()
       } else {
         this.err(res.message);
       }
     })
  };


  // 加入心愿单
  cartToWish(id) {
    cartToWish1[this.state.status](id).then(res => {
      if (res.code === 200) {
        this.getList()
      } else {
        this.err(res.message);
      }
    })
  };

  // 从购物车转到精品店
  cartToBoutique(id) {
    cartToBoutique1[this.state.status](id).then(res => {
      if (res.code === 200) {
        this.getList()
      } else {
        this.err(res.message);
      }
    });
  }

  // 从精品店转到购物车
  boutiqueToCart(id) {
    boutiqueToCart1[this.state.status](id).then(res => {
      if (res.code === 200) {
        this.getList()
      } else {
        this.err(res.message);
      }
    });
  }

  err(msg) {
    this.setState({ errTip: { title: msg, show: true } });
    setTimeout(() => {
      this.setState({ errTip: { title: '', show: false } });
    }, 2000)
  }


  bagGoodEle = (item) => {
    let lang = localStorage.getItem('lang');
    let { url } = getImg(JSON.parse(item.goodsItemDomain.thumb));
    // console.log(item)
    return (
      <div className="good-item" key={item.itemId}>
        <div className="goot-pic">
          <img src={url} />
        </div>
        <div className="good-info">
          <div
            className="name ellipsis">{lang === '1' ? item.goodsName : item.goodsEnName}</div>
          <div className="code">{intl.get('Product_No.') + " " + item.goodsCode}</div>
          <div className="attr">
            <span>{lang === '1' ? item.goodsItemDomain.name : item.goodsItemDomain.enName}</span>
            <span>{item.sizeDomain.name}</span>
            <span>{intl.get('Qty.') + " "} </span>
          </div>
          {/* <span className="count" style={{fontSize: '14px'}}>
                <CloseOutlined style={{fontSize: '11px'}}/>&nbsp;<span>{item.num}</span>
              </span> */}
          <div className="price">{intl.get('Unit_Price')}&nbsp;&nbsp;&nbsp;{intl.get('money')} {item.goodsPrice}</div>
          {item.goodsDisPrice && <div className="price">{intl.get('Sale_Price')}&nbsp;&nbsp;&nbsp;{intl.get('money')} {item.goodsDisPrice}</div>}
        </div>
        <div className="action">
          <span className="iconfont icon-jingpindian1" style={{ fontSize: '16px' }} onClick={() => { this.cartToBoutique(this.state.status === 0 ? item.id : item.formId) }}></span>
          {!item.isInWish ? <HeartOutlined onClick={() => { this.cartToWish(this.state.status === 0 ? item.id : item.formId) }} /> : <HeartFilled style={{ color: 'red' }} />}
          <CloseOutlined onClick={() => { this.removeFromCart(this.state.status === 0 ? item.id : item.formId) }} />
        </div>
      </div>
    )
  };

  storeGoodEle = (item) => {
    let lang = localStorage.getItem('lang');
    let { url } = item ? getImg(JSON.parse(item.goodsItemDomain.thumb)) : '';
    return (
      <div className="good-item" key={item.itemId}>
        <div className="goot-pic">
          <img src={url} alt="" />
        </div>
        <div className="good-info">
          <div className="name ellipsis">{lang === '1' ? item.goodsName : item.goodsEnName}</div>
          <div className="code">{intl.get('Product_No.') + " " + item.goodsCode}</div>
          <div className="attr">
            <span>{lang === '1' ? item.goodsItemDomain.name : item.goodsItemDomain.enName}</span>
            <span>{item.sizeDomain.name}</span><span>{intl.get('Qty.') + " "}</span>
          </div>
          {/* <span className="count" style={{ fontSize: '14px' }}>
            <CloseOutlined style={{ fontSize: '11px' }} />&nbsp;<span>{item.num}</span>
          </span> */}
          <div className="price">{intl.get('Unit_Price')}&nbsp;&nbsp;&nbsp;{intl.get('money')} {item.goodsPrice}</div>
          {item.goodsDisPrice && <div className="price">{intl.get('Sale_Price')}&nbsp;&nbsp;&nbsp;{intl.get('money')} {item.goodsDisPrice}</div>}
        </div>
        <div className="action">
          <span className="iconfont icon-gouwudai" style={{ fontSize: '16px' }} onClick={() => { this.boutiqueToCart(this.state.status === 0 ? item.id : item.formId) }}></span>
          {!item.isInWish ? <HeartOutlined onClick={() => { this.cartToWish(this.state.status === 0 ? item.id : item.formId) }} /> : <HeartFilled style={{ color: 'red' }} />}
          <CloseOutlined onClick={() => { this.removeFromCart(this.state.status === 0 ? item.id : item.formId) }} />
        </div>
      </div>
    )
  };

  wishListEle  = (item) => {
    console.log(item);
    let lang = localStorage.getItem('lang');

    let { url } = item ? getImg(JSON.parse(item.goodsItemDomain.thumb)) : '';

    return (
        <div  className="good-item" key={item.itemId}>
          <div className="goot-pic">
            <img src={url} alt="" />
          </div>
          <div className="good-info">
            <div className="name ellipsis">{lang === '1' ? item.goodsName : item.goodsEnName}</div>
            <div className="code">{intl.get('Product_No.') + " " + item.goodsCode}</div>
            <div className="attr">
              <span>{lang === '1' ? item.goodsItemDomain.name : item.goodsItemDomain.enName}</span>
              <span>{item.sizeDomain.name}</span><span>{intl.get('Qty.') + " " +item.num}</span>
            </div>

            <div className="price">{intl.get('Unit_Price')}&nbsp;&nbsp;&nbsp;{intl.get('money')} {item.goodsPrice}</div>
            {item.goodsDisPrice && <div className="price">{intl.get('Sale_Price')}&nbsp;&nbsp;&nbsp;{intl.get('money')} {item.goodsDisPrice}</div>}
          </div>
          <div className="action">
            {/*<span className="iconfont icon-gouwudai" style={{ fontSize: '16px' }} onClick={() => { this.boutiqueToCart(this.state.status === 0 ? item.id : item.formId) }}></span>
            {!item.isInWish ? <HeartOutlined onClick={() => { this.cartToWish(this.state.status === 0 ? item.id : item.formId) }} /> : <HeartFilled style={{ color: 'red' }} />}*/}
            <CloseOutlined onClick={() => { this.removeFromWish(item)}} />

          </div>

        </div>
    )
  }




  render() {
    if (this.state.loading) {
      return <div className="header-box"></div>
    }

    const langValue = this.state.lang.filter(lang => lang.key === Number(window.localStorage.getItem('lang')))[0].value

    /*导航下拉菜单*/
    const navDetail = (<NavDetail {...this.state.subNavItem} hide={this.hideSubNav} />);

    /*购物袋下拉框*/
    const bag = (<div className="opt-box shopping-bag">
      <div className="top">
        <span className="iconfont icon-gouwudai"></span>
        <span className="opt-title">{intl.get('Shopping_Bag')}</span>
      </div>
      <div className="content">
        {/*无商品*/}
        {!this.state.cartList.length && <div className="opt-count">{intl.get('Shopping_Bag')} ( 0 )</div>}
        {/*有商品*/}
        {this.state.cartList.length ?
          <div className="good-list">
            {this.state.cartList.map(item => {
              return this.bagGoodEle(item)
            })}
            <div className="total">
              <span>{intl.get('Subtotal')} <span className="num">{intl.get('money')}&nbsp;{this.state.cartTotalPrice}</span></span>
            </div>
          </div> : ''}

        <a href={this.state.returnAndExchangeUrl} className="link">{intl.get('return_and_exchange')}<i className="arrow"></i></a>
        <a href={this.state.deliveryTimeAndCostUrl} className="link">{intl.get('delivery_time_and_cost')}<i className="arrow"></i></a>
      </div>
      {
        this.state.status === 1 ?
          <a href='/#/' className="footer">
            {intl.get('continue_shopping')}
          </a>
          :
          <a href="/checkout/initOrder" className="footer">{intl.get('CHECKOUT')}</a>
      }
    </div>);

    /*精品店预约下拉框*/
    const store = (<div className="opt-box boutique-reserve">
      <div className="top">
        <span className="iconfont icon-jingpindian"></span>
        <span className="opt-title">{intl.get('STORE_RESERVATION')}</span>
      </div>
      <div className="content">
        {/*无商品*/}
        {!this.state.reservationList.length ? <div className="opt-count">{intl.get('Store_Reservation')}（0）</div> :
          // 有商品
          <div className="good-list">
            {this.state.reservationList.map(item => {
              return this.storeGoodEle(item)
            })}
          </div>}

        <a href={this.state.menUrl} className="link">{intl.get('Shop_Men')}<i className="arrow"></i></a>
        <a href={this.state.womenUrl} className="link">{intl.get('Shop_Women')}<i className="arrow"></i></a>
        <a href='/content/specialService?nowIndex=5' className="link">{intl.get('What_is_Store_Reservation')}</a>
      </div>
      <a href="/#/" className="footer">{intl.get('select_store')}</a>
    </div>);

    /*愿望清单下拉菜单*/
    const wish = (
        <div className="opt-box">
          <div className="top">
            <span className="iconfont icon-xihuan"></span>
            <span className="opt-title">{intl.get('wish_list')}</span>
          </div>
          <div className="content">

            {/*无商品*/}
            {!this.state.wishList.length ? <div className="opt-count">{intl.get('wish_list')}（0）</div> :
                // 有商品
             <div className="good-list">
               {this.state.wishList.map(item => {
                 return this.wishListEle(item)
               })}
             </div>}

            <a href="/cart/wishlist" className="footer">{intl.get('continue_wish')}</a>
          </div>
        </div>
    )

    //在线客服地址
    let lang = localStorage.getItem('lang');
    const onlineServerUrl = "https://shiatzychen.soboten.com/chat/pc/v2/index.html?sysnum=5c9e23bf21894d1e87f68939845f5d21&channelid=2&locale="
    let newOnlineServerUrl = "";
    let newOnlineServerContent = ""
    if(lang==="3"){
      newOnlineServerUrl = onlineServerUrl+"en"
      newOnlineServerContent = "ONLINE SERVICE"
    }else{
      newOnlineServerUrl = onlineServerUrl+"cn"
      newOnlineServerContent = "在线客服"
    }



    return (
      <div className="header-box">
        <div className="header-top">
          {/* 语言 */}
          <div className="choose-area">
            <span className="lang-box" onClick={() => this.setState({ langVisible: true })}>
              {intl.get('lang_unit')}
              <span className="iconfont icon-xiajiantou"></span>
            </span>
          </div>

          {/*logo*/}
          <a className="logo-con" href="/#/">
            <img src={logo} alt="SHIATZY CHEN" className="header-logo" />
          </a>

          {/* 右侧操作区 */}
          <div className="header-opt-box">
            <div className="opt-list">
              {/*登陆注册*/}
              {this.state.status ? <div className="opt-item">
                <Dropdown overlay={this.openLoginModal()} trigger="click" visible={this.state.visible} placement="bottomRight"
                  onVisibleChange={this.handleVisibleChange} getPopupContainer={() => document.getElementById('user-action')}>
                  <div className="user" id="user-action">
                    <span><a href={this.state.loginLink}>{intl.get('Login_in')}</a></span>
                    <span style={{ margin: '0 3px' }}>|</span>
                    <span><a href={this.state.registerLink}>{intl.get('Register')}</a></span>
                  </div>
                </Dropdown>
              </div> :
                <div className="opt-item">
                  <a className="user-con" href="/u/account/index"><img className="user-logo" src={userImg} /></a>
                </div>}
              {/*购物袋*/}
              <Dropdown overlay={bag} getPopupContainer={() => document.getElementById('shopping-bag')}>
                <div className="opt-item" id="shopping-bag">
                  <span className="iconfont icon-gouwudai"></span>
                  <div className="count shopping-bag-count">{this.state.cartNum ? this.state.cartNum : '0'}</div>
                </div>
              </Dropdown>
              {/*精品店预约*/}
              <Dropdown overlay={store} getPopupContainer={() => document.getElementById('reserve-bag')}>
                <div className="opt-item" id="reserve-bag">
                  <span className="iconfont icon-jingpindian"></span>
                  <div className="count boutique-reserve-count">{this.state.reservationNum ? this.state.reservationNum : '0'}</div>
                </div>
              </Dropdown>
              {/*愿望清单*/}
              <Dropdown overlay={wish} getPopupContainer={() => document.getElementById('wish-list')}>
                <div className="opt-item" id="wish-list">
                  <span className="iconfont icon-xihuan"></span>
                  <div className="count boutique-reserve-count">{this.state.wishNum ? this.state.wishNum : '0'}</div>
                </div>
              </Dropdown>

              {/*搜索*/}
              <div className="opt-item" onClick={() => this.setState({ searchVisible: !this.state.searchVisible })}>
                <span className="iconfont search"></span>
              </div>
            </div>

          </div>
        </div>

        <div className="header-nav" id="header-nav">
          <ul>
            {
              this.state.navList.map((nav, index, arr) => {
                let toUrl = "/";
                let ele = '';
                let curHref = window.location.href;
                let extraClass = '';
                switch (nav.id) {
                  case 223:
                    toUrl = "/stories";
                    ele = (<Link to={toUrl}>{nav.name}</Link>);
                    if (curHref.indexOf('stories') > -1 || curHref.indexOf('story') > -1) {
                      extraClass = 'selected'
                    }
                    break;
                  case 222:
                    toUrl = "/runway";
                    ele = (<Link to={toUrl}>{nav.name}</Link>);
                    if (curHref.indexOf('runway') > -1) {
                      extraClass = 'selected'
                    }
                    break;
                  default:
                    // toUrl = nav.link + nav.id;
                    toUrl = nav.path;
                    // toUrl = "javascript:void(0);";
                    ele = (<a href={toUrl}>{nav.name}</a>);
                    extraClass = '';
                    break;
                }
                return (
                  <li className={`nav-item ${extraClass}`} key={nav.id} onMouseOver={() => this.navHover(nav)} style={{ position: 'relative' }}>
                    <Dropdown
                      onVisibleChange={this.handleNavVisibleChange}
                      overlay={navDetail} overlayClassName="nav-detail-box"
                      getPopupContainer={() => document.getElementById('header-nav')}
                    >
                      {ele}
                    </Dropdown>
                    {/*<NavDetail {...arr[index]} />*/}
                  </li>
                )
              })
            }
          </ul>

          {/*{
            this.state.subNavVisible ?
              <NavDetail {...this.state.subNavItem} hide={this.hideSubNav} />
              : null
          }*/}
        </div>

        {/* 搜索框 */}
        <div className={`search-box ${this.state.searchVisible && 'search-box1'}`}>
          <input type="text"
            value={this.state.searchValue}
            onMouseEnter={this.doSearch}
            onChange={this.onInputChange}
            placeholder={intl.get('search_placeholder')}
          />
          <span className="iconfont icon-sousuo1" onClick={this.doSearch}></span>
        </div>

        <SelectLang visible={this.state.langVisible} close={() => { localStorage.setItem('firstInitLang', true); this.setState({ langVisible: false }) }} />

        {this.state.errTip.show && <div className="errtip">{this.state.errTip.title}</div>}

      </div>
    )
  }
}

export default withRouter(CommonHeader)